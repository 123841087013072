import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Table,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { METHOD_POST, METHOD_PUT } from '../../../../utils/constants';
import { getSupplierApi } from '../../../../api/supplier';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import { PAYMENT_TERMS_CATALOG_ID } from '../../../../helpers/constants';
import { formatMoney } from '../../../../utils/general';
import EditLine from './EditLine/EditLine';
import Modal from '../../../General/Modal';

const AddPurchaseOrder = ({
  setModalIsVisible,
  purchaseOrders,
  idPurchaseOrder,
  setReloadTable,
  setReloadBills,
  setKeysSelected,
}) => {
  const [formPurchaseOrder] = Form.useForm();
  const token = getAccessTokenApi();
  const [loadingForm, setLoadingForm] = useState(true);
  const [supplier, setSupplier] = useState({});
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState(purchaseOrders);
  const [modalEditIsVisible, setModalEditIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);

  useEffect(() => {
    let supplierId = purchaseOrders[0].supplierId;
    getSupplierApi(token, supplierId).then((response) => {
      setSupplier(response.result);
    });
    getCatalogValueHandle(
      PAYMENT_TERMS_CATALOG_ID,
      setPaymentTerms,
      setLoadingForm
    );
  }, [purchaseOrders, token]);
  useEffect(() => {
    const fields = {
      supplierName: supplier.name,
      fiscalID: supplier.fiscalID,
      paymentTermsId: supplier.paymentTermsId,
    };
    formPurchaseOrder.setFieldsValue(fields);
  }, [supplier, formPurchaseOrder]);

  useEffect(() => {
    let countSubtotal = 0;
    let countTaxAmount = 0;
    let countTotal = 0;
    data?.forEach((element) => {
      countSubtotal += element.subtotal;
      countTaxAmount += element.taxAmount;
      countTotal += element.paymentAmount
        ? element.paymentAmount
        : element.balance;
    });
    formPurchaseOrder.setFieldsValue({
      total: countTotal,
      subtotal: countSubtotal,
      taxAmount: countTaxAmount,
    });
  }, [data, formPurchaseOrder]);

  const columns = [
    {
      title: 'Folio factura',
      dataIndex: 'invoiceNum',
      key: 'invoiceNum',
      width: 100,
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      align: 'center',
      key: 'subtotal',
      width: 150,

      render: (subtotal) => `$${formatMoney(subtotal)}`,
    },
    {
      title: 'Impuesto',
      dataIndex: 'taxAmount',
      align: 'center',
      key: 'taxAmount',
      width: 150,

      render: (taxAmount) => `$${formatMoney(taxAmount)}`,
    },
    {
      title: 'Termino de pago',
      dataIndex: 'paymentTerms',
      key: 'paymentTerms',
      align: 'center',
      width: 100,
    },
    {
      title: 'Tipo de pago',
      dataIndex: 'paymentType',
      key: 'paymentType',
      width: 120,
      render: (paymentType) => (
        <>{paymentType === '' ? 'TOTAL' : paymentType}</>
      ),
      editable: true,
    },
    {
      title: 'Monto ',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      width: 120,
      render: (paymentAmount, record) => (
        <>
          {paymentAmount
            ? `$${formatMoney(paymentAmount)}`
            : `$${formatMoney(record.balance)}`}
        </>
      ),
      editable: true,
    },
    {
      title: 'Editar',
      dataIndex: 'operation',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return (
          <Button
            type="primary"
            size="small"
            disabled={editingKey !== '' || record.paymentTerms !== 'ANTICIPADO'}
            icon={<EditOutlined />}
            onClick={() => showModal(record)}
          ></Button>
        );
      },
    },
  ];
  const addPurchaseOrderFunction = async (values) => {
    setLoadingForm(true);
    values['SupplierId'] = data[0].supplierId;
    values['Subtotal'] = parseFloat(values.subtotal);
    values['Total'] = parseFloat(values.total);
    values['TaxAmount'] = parseFloat(values.taxAmount);
    data?.map((element) => {
      element['APInvoiceId'] = element.id;
    });
    if (idPurchaseOrder) {
      data?.map((element) => {
        element['SalesOrderId'] = idPurchaseOrder;
      });
      values['id'] = idPurchaseOrder;
    }
    values['SOAPInvoices'] = data;

    let config = {
      method: idPurchaseOrder ? METHOD_PUT : METHOD_POST,
      url: idPurchaseOrder ? `SalesOrder/${idPurchaseOrder}` : `SalesOrder`,
      data: values,
      token,
      showNotification: false,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          if (response.statusCode === 200) {
            setModalIsVisible(false);
            if (idPurchaseOrder) {
              notification['success']({
                description: 'Orden de compra editada exitosamente.',
              });
            } else {
              notification['success']({
                description: 'Orden de compra generada exitosamente.',
              });
            }
          }
          if (response.statusCode === 400) {
            notification['error']({
              description: 'Ocurrió un error, inténtelo más tarde.',
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingForm(false);
        if (idPurchaseOrder) {
          setReloadTable(true);
        } else {
          setReloadBills(true);
          setKeysSelected([]);
        }
      });
  };
  const showModal = (record) => {
    setModalEditIsVisible(true);
    setTitleModal('Editar tipo de pago y monto');
    setContextModal(
      <EditLine
        setModalEditIsVisible={setModalEditIsVisible}
        record={record}
        data={data}
        setData={setData}
      ></EditLine>
    );
  };
  return (
    <div>
      <Form
        name="add-promo"
        layout="vertical"
        form={formPurchaseOrder}
        onFinish={addPurchaseOrderFunction}
        // onFinishFailed={errorFields}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} md={8}>
            <Form.Item label="Proveedor:" name="supplierName">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="RFC:" name="fiscalID">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item label="Término de pago:" name="paymentTermsId">
              <Select placeholder="Selecciona una opción" disabled>
                {paymentTerms.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 950 }}
            loading={loadingForm}
          />
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={6}>
            <Form.Item label="Subtotal:" name="subtotal">
              <InputNumber
                disabled
                style={{ width: '100%' }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item label="Impuesto:" name="taxAmount">
              <InputNumber
                disabled
                style={{ width: '100%' }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col xs={0} md={6}>
            {/* <Form.Item label="RFC:" name="fiscalID">
              <Input disabled />
            </Form.Item> */}
          </Col>
          <Col xs={24} md={6} justify={'end'}>
            <Form.Item label="Total:" name="total">
              <InputNumber
                disabled
                style={{ width: '100%' }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            htmlType="submit"
            className="btn-download"
            style={{ width: 160 }}
          >
            Guardar
          </Button>
        </Row>
      </Form>
      <Modal
        title={titleModal}
        visible={modalEditIsVisible}
        setIsVisible={setModalEditIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default AddPurchaseOrder;
