import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { METHOD_GET } from '../../../../utils/constants';
import { fetchApi } from '../../../../helpers/fetch';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getAccessTokenApi } from '../../../../api/auth';
import CustomerRoutesTable from '../../../../components/PortalClients/Customers/CustomerRoutesTable';
import BackButton from '../../../../components/General/BackButton/BackButton';

export default function CustomerRoutesPage() {
  let { clientId } = useParams();
  const token = getAccessTokenApi();
  const [client, setClient] = useState({});

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `customer/GetCustomer?id=${clientId}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let clientt = response.result;
          setClient(clientt);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [clientId, token]);

  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop client={client} />
      <div className="clients__container">
        <CustomerRoutesTable client={client} />
      </div>
    </Layout>
  );
}

function HeaderTop({ client }) {
  const { Title } = Typography;
  return (
    <div className="clients__container">
      <Row xs={23} className="header-top">
        <Col xs={19} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Rutas {client.name}
          </Title>
        </Col>
        <Col xs={5}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/clientes'}></BackButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
