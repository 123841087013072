import React, { useEffect, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Avatar,
  notification,
  Modal as ModalAntd,
  Upload,
  message,
  Tooltip,
  Tag,
  Alert,
} from 'antd';
import logo_planb from '../../../assets/img/png/logo_planb.png';
import profileUser from '../../../assets/img/png/profileUser.png';
import {
  UploadOutlined,
  LockOutlined,
  CloseCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import useUser from '../../../hooks/useUser';
import { logOutError } from '../../../utils/feedbackMessages';
import './Profile.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  changePasswordApi,
  changeProfilePictureApi,
  changeProfileInfoApi,
  getPortalTypeApi,
} from '../../../api/user';
import { logout, getAccessTokenApi } from '../../../api/auth';
import {
  validatePassword,
  validateTextHasCapitalize,
  validateTextHasLowercase,
  validateTextHasNumber,
  validateTextHasCharacter,
} from '../../../utils/formValidation';
export default function Profile() {
  const { Title } = Typography;
  const [loadingForm, setLoadingForm] = useState(false);
  const [rfcPerson, setRfcPerson] = useState(null);
  const [addressPerson, setAddressPerson] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [image, setImage] = useState({
    imageUrl: '',
  });
  const { imageUrl } = image;
  const [passwordForm] = Form.useForm();
  const [profileForm] = Form.useForm();
  const { person, setPerson } = useUser();
  const portalType = getPortalTypeApi();
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const [hasCharacter, setHasCharacter] = useState(true);
  const [lenghtCorrect, setLenghtCorrect] = useState(true);
  const { Text } = Typography;
  const [filesList, setFileList] = useState({});
  const [hasUpperCase, setHasUpperCase] = useState(true);
  const [hasLowerCase, setHasLowerCase] = useState(true);
  const [hasNumber, setHasNumber] = useState(true);
  const [lenghtMaxCorrect, setLenghtMaxCorrect] = useState(true);

  useEffect(() => {
    if (person) {
      // console.log(person, 'person');
      profileForm.setFieldsValue({
        Name: person.name,
        fiscalID: person.fiscalID,
        Phone: person.phone,
        Address: person.address,
        Email: person.email,
        PhoneNumber: person.phoneNumber,
        UserName: person.userName,
      });
      passwordForm.setFieldsValue({
        UserName: person.userName,
      });
      setRfcPerson(person.fiscalID);
      setAddressPerson(person.address);
      const data = person.profilePicture;
      if (data !== '') {
        const imageProfile = `data:image/jpeg;base64, ${data}`;
        setProfilePicture(imageProfile);
      } else {
        setProfilePicture(null);
      }
    } else {
      logOutError();
    }
  }, [passwordForm, profileForm, person]);

  const checkPassword = (text) => {
    if (validateTextHasCapitalize(text)) {
      setHasUpperCase(true);
    } else {
      setHasUpperCase(false);
    }

    if (validateTextHasLowercase(text)) {
      setHasLowerCase(true);
    } else {
      setHasLowerCase(false);
    }

    if (validateTextHasNumber(text)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }
    if (validateTextHasCharacter(text)) {
      setHasCharacter(true);
    } else {
      setHasCharacter(false);
    }
    if (text.length >= 6) {
      setLenghtCorrect(true);
    } else {
      setLenghtCorrect(false);
    }
    if (text.length <= 10) {
      setLenghtMaxCorrect(true);
    } else {
      setLenghtMaxCorrect(false);
    }
  };

  const updateProfile = async (values) => {
    confirm({
      title: 'Actualizar perfil',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setLoadingForm(true);
        let data = {
          Name: values.Name,
          fiscalID: values.fiscalID,
          Address: values.Address,
          PhoneNumber: values.PhoneNumber,
        };
        changeProfileInfoApi(token, data)
          .then((response) => {
            // console.log(response, 'response profile');

            if (response.statusCode === 200) {
              setPerson({
                ...person,
                name: response.result.name,
                phoneNumber: response.result.phoneNumber,
                address: response.result.address,
                fiscalID: response.result.fiscalID,
              });
              notification['success']({
                message: 'Datos editados con éxito',
              });
              return;
            }
            if (response === undefined) {
              notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
              });
              return false;
            }
            if (response.statusCode === 401) {
              notification['error']({
                message: 'Usuario no autorizado.',
              });
              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return false;
            }
            if (response.statusCode === 400) {
              notification['error']({
                message: response.description,
              });
            } else {
              let messageError = 'Ocurrió un error, Inténtelo otra vez';
              if (response.statusCode === 409) {
                messageError = response.description;
              }

              notification['error']({
                message: messageError,
              });
            }
          })
          .catch((err) => {})
          .finally(() => setLoadingForm(false));
      },
      onCancel() {},
    });
  };
  const updatePassword = async (values) => {
    confirm({
      title: 'Actualizar contraseña',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setLoadingForm(true);
        let data = {
          OldPassword: values.OldPassword,
          NewPassword: values.NewPassword,
          ConfirmPassword: values.ConfirmPassword,
        };
        changePasswordApi(token, data)
          .then((response) => {
            if (response.statusCode === 200) {
              notification['success']({
                message: 'Contraseña modificada con éxito',
              });
              setPerson({
                ...person,
                passwordChanged: true,
              });
              return;
            }
            if (response === undefined) {
              notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
              });
              return false;
            }
            if (response.statusCode === 401) {
              notification['error']({
                message: 'Usuario no autorizado.',
              });
              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return false;
            }
            if (response.statusCode === 400) {
              notification['error']({
                message: response.description,
              });
            } else {
              let messageError = 'Ocurrió un error, Inténtelo otra vez';
              if (response.statusCode === 409) {
                messageError = response.description;
              }
              notification['error']({
                message: messageError,
              });
            }
          })
          .catch((err) => {})
          .finally(() => setLoadingForm(false));
      },
      onCancel() {},
    });
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir archivos JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La imegen debe ser menos de 2MB!');
    }
    //   return isJpgOrPng && isLt2M;
    return false;
  }

  const handleChange = (info) => {
    let fileList = [...info.fileList];

    if (Array.isArray(fileList) && fileList.length) {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      let file = fileList[0];

      getBase64(file.originFileObj, (imageUrl) => {
        setImage({
          imageUrl: imageUrl,
        });
        updateImageFunction(imageUrl);
      });
    } else {
      setImage({
        imageUrl: null,
      });
    }
    setFileList({ fileList });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList.slice(-1);
  };

  const updateImageFunction = async (image) => {
    setLoadingForm(true);
    const data = {
      ProfilePicture: image.split(',')[1],
      RefreshToken: '',
    };
    changeProfilePictureApi(token, data)
      .then((response) => {
        if (response === undefined) {
          notification['error']({
            message: 'Ocurrió un error, Inténtelo más tarde',
          });
          return false;
        }
        if (response.statusCode === 200) {
          notification['success']({
            message: 'Imágen de perfil actualizada con éxito',
          });
          setPerson({
            ...person,
            profilePicture: image.split(',')[1],
          });
          return;
        }
        if (response.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado.',
          });
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return false;
        }
        if (response.statusCode === 400) {
          notification['error']({
            message: response.description,
          });
        } else {
          let messageError = 'Ocurrió un error, Inténtelo otra vez';
          if (response.statusCode === 409) {
            messageError = response.description;
          }

          notification['error']({
            message: messageError,
          });
        }
      })
      .finally(() => setLoadingForm(false));
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <div className="header-top__left">
        {person && person.passwordChanged === false && (
          <Alert
            message="Alerta"
            description="Debes realizar el cambio de contraseña para poder navegar en el portal."
            type="warning"
            showIcon
            closable
          />
        )}
        <div className="perfil-form__container-image">
          <Avatar
            size={200}
            width={200}
            src={profilePicture ? profilePicture : profileUser}
          />
        </div>
        <Row
          style={{
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Form name="change-profilePicture">
            <Form.Item
              style={{ alignItems: 'center' }}
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Imagen adjunta"
            >
              <Upload
                name="logo"
                beforeUpload={beforeUpload}
                listType="picture"
                multiple={false}
                onChange={handleChange}
                fileList={filesList}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>
                  Click para cargar la imagen
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        </Row>
        <Row xs={24}>
          <Col md={11} xs={24} style={{ marginRight: 30 }}>
            <Form
              name="change-profile"
              layout="vertical"
              form={profileForm}
              onFinish={updateProfile}
            >
              <div className="header-top__left">
                <Title level={3}>Información del perfil</Title>
                <div style={{ marginBottom: 15 }}>
                  <p>Roles:</p>
                  {person &&
                    person.roles.map((rol) => {
                      let color = '';
                      switch (rol) {
                        case 'Customer':
                          color = 'blue';
                          break;
                        case 'Supplier':
                          color = 'purple';
                          break;
                        case 'Admin':
                          color = 'red';
                          break;

                        case 'Operaciones':
                          color = 'green';
                          break;
                        case 'Manager':
                          color = 'orange';
                          break;
                        case 'Layout':
                          color = 'gold';
                          break;
                        case 'Financial':
                          color = 'yellow';
                          break;

                        default:
                          break;
                      }
                      let name = rol;

                      switch (rol) {
                        case 'Customer':
                          name = 'Cliente';
                          break;
                        case 'Supplier':
                          name = 'Proveedor';
                          break;
                        case 'Financial':
                          name = 'Financiero';
                          break;
                        case 'Manager':
                          name = 'Gerente';
                          break;
                        default:
                          break;
                      }

                      return (
                        <Tag color={color} key={rol}>
                          {name}
                        </Tag>
                      );
                    })}
                  <p style={{ marginTop: 10 }}>Acceso al portal:</p>
                  {portalType && (
                    <Tag
                      color={
                        portalType === 'Clientes'
                          ? 'blue'
                          : portalType === 'Proveedores'
                          ? 'red'
                          : 'purple'
                      }
                    >
                      {portalType}
                    </Tag>
                  )}
                </div>
                <Form.Item
                  label="Nombre:"
                  name="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un nombre',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
                {rfcPerson && (
                  <Form.Item
                    label="RFC:"
                    name="fiscalID"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese un RFC',
                      },
                    ]}
                  >
                    <Input placeholder="Ingresa tu información" />
                  </Form.Item>
                )}
                <Form.Item
                  label={
                    <span>
                      Teléfono:&nbsp;
                      <Tooltip title="El número telefónico debe contener 10 dígitos">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="PhoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa número telefónico',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || value.toString().length === 10) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          'Por favor ingresa número telefónico a 10 dígitos'
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
                {addressPerson && (
                  <Form.Item
                    label="Dirección:"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese un nombre',
                      },
                    ]}
                  >
                    <Input placeholder="Ingresa tu información" />
                  </Form.Item>
                )}
                <Form.Item label="Correo electrónico:" name="Email">
                  <Input placeholder="Ingresa tu información" disabled />
                </Form.Item>
                <Row justify="end">
                  <Button
                    style={{ marginBottom: 20, width: 150 }}
                    htmlType="submit"
                    className="btn-enviar"
                  >
                    Actualizar perfil
                  </Button>
                </Row>
              </div>
            </Form>
          </Col>
          <Col md={11} xs={24}>
            <Form
              name="change-password"
              layout="vertical"
              form={passwordForm}
              onFinish={updatePassword}
            >
              <div className="header-top__left">
                <Title level={3}>Cambio de contraseña</Title>
                <Form.Item label="Usuario:" name="UserName">
                  <Input disabled placeholder="Ingresa tu información" />
                </Form.Item>
                <Form.Item
                  label="Contraseña actual:"
                  name="OldPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese contraseña actual',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Ingresa tu información"
                  />
                </Form.Item>
                <Form.Item
                  label="Contraseña nueva:"
                  name="NewPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese contraseña nueva',
                    },
                    () => ({
                      validator(rule, value) {
                        if (
                          validatePassword(value) &&
                          lenghtCorrect &&
                          lenghtMaxCorrect
                        ) {
                          return Promise.resolve();
                        } else if (!validatePassword(value)) {
                          return Promise.reject();
                        }
                      },
                    }),
                  ]}
                  hasFeedback
                  onChange={(e) => checkPassword(e.target.value)}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Ingresa tu información"
                  />
                </Form.Item>
                {!hasUpperCase && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Una mayúscula{' '}
                  </Text>
                )}
                <Row>
                  {!hasLowerCase && (
                    <Text type="danger">
                      <CloseCircleFilled style={{ marginRight: 8 }} />
                      Una minúscula{' '}
                    </Text>
                  )}
                </Row>
                <Row>
                  {!hasNumber && (
                    <Text type="danger">
                      <CloseCircleFilled style={{ marginRight: 8 }} />
                      Un número{' '}
                    </Text>
                  )}
                </Row>
                {!hasCharacter && (
                  <Col>
                    <Text type="danger">
                      <CloseCircleFilled style={{ marginRight: 8 }} />
                      Un carácter: {'?()!@#$%^&*[]"\';:_-<>. =+/'}
                    </Text>
                  </Col>
                )}
                {!lenghtCorrect && (
                  <Col>
                    <Text type="danger">
                      <CloseCircleFilled
                        style={{
                          marginRight: 8,
                          marginBottom: 10,
                        }}
                      />
                      Tiene 6 o más caracteres{' '}
                    </Text>
                  </Col>
                )}
                {!lenghtMaxCorrect && (
                  <Col>
                    <Text type="danger">
                      <CloseCircleFilled style={{ marginRight: 8 }} />
                      Máximo 10 caracteres{' '}
                    </Text>
                  </Col>
                )}
                <Form.Item
                  label="Confirmar contraseña:"
                  name="ConfirmPassword"
                  dependencies={['NewPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Por favor confirme la contraseña',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('NewPassword') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          'Las dos contraseñas ingresadas no son iguales'
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Ingresa tu información"
                  />
                </Form.Item>
                <Row justify="end">
                  <Button
                    htmlType="submit"
                    className="btn-enviar"
                    style={{ width: 170 }}
                  >
                    Actualizar contraseña
                  </Button>
                </Row>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}
