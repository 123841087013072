/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Divider,
  Modal as ModalAntd,
  notification,
  Spin,
  Alert,
  Button,
} from 'antd';
import moment from 'moment';
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import Modal from '../../../General/Modal';
import OperationLine from './OperationLine';
import CustomersTable from '../../../PortalClients/Customers/CustomersTable';
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
} from '../../../../utils/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { API_URL_OPERATION } from '../../../../helpers/urls';
import useUser from '../../../../hooks/useUser';
import OperationHeader from './OperationHeader/OperationHeader';
import QuotesTable from './OperationHeader/QuotesTable/QuotesTable';
import OperationsTable from './../OperationsTable';
import {
  AEREO_VALUE_ID,
  MARITIMO_VALUE_ID,
  TERRESTRE_VALUE_ID,
} from '../../../../helpers/constants';

const AddEditOperationForm = (props) => {
  const {
    setModalIsVisible: setModalOperationIsVisible,
    type: typeOperation,
    operationId: idEdit,
    setReloadTable,
  } = props;
  const [loadingForm, setLoadingForm] = useState(false);
  const [generalOperationForm] = Form.useForm();
  const [pieces, setPieces] = useState([]);
  const [disableAllInputs, setDisableAllInputs] = useState(
    typeOperation === 'watch' ? true : false
  );
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [linesData, setLinesData] = useState([]);
  const [clientSelectedOperation, setClientSelectedOperation] = useState(null);
  const [customerContacts, setCustomerContacts] = useState({
    data: [],
    loading: true,
  });
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const { person } = useUser();
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const [quoteSelected, setQuoteSelected] = useState(null);
  const [quoteId, setQuoteId] = useState(null);
  const [idSelected, setIdSelected] = useState(null);
  const [lines, setLines] = useState([]);
  const [disabledInputs, setDisabledInputs] = useState(true);
  const [clearHeader, setClearHeader] = useState(false);
  const [reloadOperation, setReloadOperation] = useState(false);
  const [basicInformationForm] = Form.useForm();
  const [linesDetailForm] = Form.useForm();
  const [additionalServices, setAdditionalServices] = useState([]);
  const [hazMatAvailable, setHazMatAvailable] = useState(false);
  const [operationIdEdit, setOperationIdEdit] = useState(null);
  const [operationId, setOperationId] = useState(null);
  const [type, setType] = useState(typeOperation);
  const [customerRoutes, setCustomerRoutes] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const [routeSelected, setRouteSelected] = useState(0);
  const [delivered, setDelivered] = useState(false);
  const [statusSelected, setStatusSelected] = useState(0);
  const [statusInitial, setStatusInitial] = useState(0);
  const [service, setService] = useState(TERRESTRE_VALUE_ID);
  const [routeCity, setRouteCity] = useState({});

  useEffect(() => {
    setOperationId(() => idEdit);
  }, [idEdit]);
  useEffect(() => {
    generalOperationForm.setFieldsValue({
      lastUpdateBy: person.userName,
    });
  }, [person]);

  useEffect(() => {
    //edit or watch
    if (operationId) {
      if (reloadOperation === true) {
        setReloadOperation(false);
        return;
      }
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_OPERATION}/${operationId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const result = response.result.operation;
            const userName = response.result.createdBy;
            const clientName = response.result.customerName || '';
            const etaDate = result.eta ? moment.unix(result.eta) : '';
            const etdDate = result.etd ? moment.unix(result.etd) : '';
            const arrivalDate = result.arrivaldate
              ? moment.unix(result.arrivaldate)
              : '';
            const reservationDate = result.reservationDate
              ? moment.unix(result.reservationDate)
              : '';
            const operation = {
              id: result.id,
              rateId: result.rateId,
              rateIdStr: result.rateIdStr,
              validity: [
                moment.unix(result.startDate),
                moment.unix(result.endDate),
              ],
              startDate: result.startDate,
              endDate: result.endDate,
              isCompleted: result.isCompleted,
              contactId: result.contactId,
              customerId: result.customerId,
              comments: result.comments,
              cancellationreason: result.cancellationreason,
              lastUpdateBy: userName,
              customer: clientName,
              typeOfLoad: result.typeOfLoad,
              originCountry: result.originCountry,
              originZipCode: result.originZipCode,
              originState: result.originState,
              originCity: result.originCity,
              originAddress: result.originAddress,
              originAirport: result.originAirport,
              originPort: result.originPort,
              destinationCountry: result.destinationCountry,
              destinationZipCode: result.destinationZipCode,
              destinationState: result.destinationState,
              destinationCity: result.destinationCity,
              destinationAddress: result.destinationAddress,
              destinationAirport: result.destinationAirport,
              destinationPort: result.destinationPort,
              routeId: result.routeId,
              MAWB: result.mawb,
              MBL: result.mbl,
              Vessel: result.vessel,
              Voyage: result.voyage,
              inlandReference: result.inlandReference,
              NoReservation: result.noReservation,
              eta: etaDate,
              etd: etdDate,
              arrivalDate: arrivalDate,
              reservationDate: reservationDate,
              insurance: result.insurance,
              invoice: result.invoice,
              insuranceAmount: result.insuranceAmount,
              incoterm: result.incoterm,
              quoteId: result.quoteId,
              operation: result.operation,
              typeOfService: result.typeOfService,
              service: result.service,
              loadType: result.loadType,
              status: result.status,
              operationIdStr: result.operationIdStr,
              quoteIdStr: result.quoteIdStr,
              hazMat: result.hazMat,
              hazMatDescription: result.hazMatDescription,
            };
            setService(result.service);
            if (result.status === 2) {
              setDelivered(true);
            }
            generalOperationForm.setFieldsValue(operation);
            generalOperationForm.setFieldsValue({
              quoteId: result.quoteId,
            });
            if (result.insurance) {
              setDisabledInputs(false);
            }
            if (result.hazMat) {
              setHazMatAvailable(true);
            }
            setClientSelectedOperation({
              id: result.customerId,
              name: clientName, //PENDING
            });
            setLines(() => result.operationLines);
            setQuoteId(result.quoteId);
            setAdditionalServices(() => result.additionalServices);
            setQuoteSelected();
            setIdSelected(result.quoteId);
            setRouteSelected(result.routeId);
            setStatusSelected(result.status);
            setStatusInitial(result.status);
            setRouteCity({
              originCity: result.originCity,
              destinationCity: result.destinationCity,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingForm(false);
        });

      // setLoadingForm(false);
    }
  }, [reloadOperation, operationId]);

  useEffect(() => {
    if (clientSelectedOperation) {
      const config = {
        method: METHOD_GET,
        url: `Route/GetByCustomerAndSupplier?customerId=${clientSelectedOperation.id}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let clientRoutes = response.result;
            let routesCustomer = [];
            if (clientRoutes) {
              routesCustomer = clientRoutes.map((e, index) => {
                return e;
              });
            }
            let routesC = routesCustomer.map((e) => ({
              value: e.routeId,
              label: `${e.originCityStr} ${e.originStateStr} - ${e.destinationCityStr} ${e.destinationStateStr}`,
            }));
            routesC.sort((a, b) => a.label.localeCompare(b.label));
            setCustomerRoutes(routesC);
            setAllRoutes(routesCustomer);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [clientSelectedOperation, token]);
  useEffect(() => {
    if (clientSelectedOperation) {
      const config = {
        method: METHOD_GET,
        url: `Customer/${clientSelectedOperation.id}/Contacts`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            const supContacts = response.result;

            if (supContacts) {
              supContacts.map((cat, index) => {
                cat['key'] = index;
                cat['value'] = cat.id;
                cat[
                  'label'
                ] = `${cat.name} ${cat.lastName} - ${cat.rolesDescription}`;

                return cat;
              });
            }
            setCustomerContacts({
              data: supContacts,
              loading: false,
            });
            if (!clientSelectedOperation.key) {
              generalOperationForm.setFieldsValue({
                customerId: clientSelectedOperation.id,
                customer: `${clientSelectedOperation.name}`,
              });
            } else {
              generalOperationForm.setFieldsValue({
                customerId: clientSelectedOperation.id,
                customer: `${clientSelectedOperation.name}`,
                contactId: null,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setCustomerContacts({
            data: [],
            loading: false,
          });
        });
    } else {
      setCustomerContacts({
        data: [],
        loading: false,
      });
      generalOperationForm.setFieldsValue({
        customerId: null,
        contactId: null,
        customer: null,
      });
    }
  }, [clientSelectedOperation, token]);
  const clearFilterOperation = React.useCallback(
    (clear) => {
      // const quoteEmpty = {
      //   loadType: null,
      //   hazMat: null,
      //   HhzMatDescription: null,
      //   service: null,
      //   typeOfService: null,
      //   unitType: null,
      //   typeOfLoad: null,
      //   operation: null,
      //   incoterm: null,
      //   insurance: null,
      //   originCity: null,
      //   originState: null,
      //   originCountry: null,
      //   destinationCity: null,
      //   destinationState: null,
      //   destinationCountry: null,
      //   insuranceAmount: null,
      //   invoice: null,
      //   quoteId: null,
      //   etd: null,
      // };
      const linesEmpty = {
        bol: null,
        purchaseOrder: null,
        equipmentType: null,
        container: null,
        containerNum: null,
        petition: null,
        mark: null,
        stamp: null,
        plate: null,
        totalNetWeight: null,
        totalTareWeight: null,
        totalGrossWeight: null,
        volume: null,
      };
      if (clear) {
        // generalOperationForm.resetFields();
        basicInformationForm.resetFields();
      }
      // generalOperationForm.setFieldsValue(quoteEmpty);
      generalOperationForm.setFieldsValue({ quoteId: null, quoteIdStr: null });
      setQuoteSelected(null);
      setIdSelected(null);
      basicInformationForm.setFieldsValue(linesEmpty);
      setClearHeader(true);
      // setClientSelectedOperation(null);
      // setDisabledInputs(true);
      setLines([]);
      setPieces([]);
      setAdditionalServices([]);
      setCustomerContacts({
        data: [],
        loading: true,
      });
    },
    [generalOperationForm, basicInformationForm]
  );
  const checkValidateInfoQuote = (values) => {
    let isValid = true;
    if (
      !values.customerId ||
      !values.loadType ||
      !values.service ||
      !values.typeOfService ||
      !values.originCity ||
      !values.originCountry ||
      !values.originState ||
      !values.destinationCity ||
      !values.destinationCountry ||
      !values.etd ||
      !values.destinationState
    ) {
      isValid = false;
    }

    return isValid;
  };
  const showModal = React.useCallback(
    (type, quote) => {
      if (type === 'Clients') {
        setModalIsVisible(true);
        setTitleModal('Clientes');
        setContextModal(
          <CustomersTable
            setModalIsVisibleQuote={setModalIsVisible}
            pickCustomer
            setClientSelectedQuote={setClientSelectedOperation}
            // type={type}
          ></CustomersTable>
        );
      } else if (type === 'Quotes') {
        // const values = generalOperationForm.getFieldInstance(true);
        const valuesForm = generalOperationForm.getFieldsValue();
        if (valuesForm.etd) {
          valuesForm['etd'] = moment(valuesForm.etd).startOf('day');
        }
        if (checkValidateInfoQuote(valuesForm)) {
          setTitleModal('Seleccionar cotización');
          setModalIsVisible(true);
          setContextModal(
            <QuotesTable
              pickQuote
              setModalIsVisibleQuote={setModalIsVisible}
              setQuoteSelected={setQuoteSelected}
              setIdSelected={setIdSelected}
              filterQuote={valuesForm}
              form={generalOperationForm}
              routeSelected={routeSelected}
              basicInformationForm={basicInformationForm}
            />
          );
        } else {
          notification.warning({
            message: 'Complete todos los campos',
            description:
              'Los campos son: Cliente, Ruta, Tipo de carga, Servicio, Tipo de servicio, ETD.',
          });
        }
      } else if (type === 'Operations') {
        setModalIsVisible(true);
        setTitleModal('Operaciones terrestres');
        setContextModal(
          <OperationsTable
            pickOperation={true}
            form={generalOperationForm}
            setModalIsVisible={setModalIsVisible}
          ></OperationsTable>
        );
      }
    },
    [generalOperationForm]
  );
  const validationOperationField = ({ values, errorFields, outOfDate }) => {
    if (!values.validity || !values.customerId || !values.contactId) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la operación.');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const handleSaveOperation = (values) => {
    confirm({
      title: !operationId ? 'Guardar operación' : 'Editar operación',
      icon: <ExclamationCircleOutlined />,
      content: !operationId
        ? '¿Estás seguro que deseas guardar operación?'
        : '¿Estás seguro que deseas editar operación?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // return;
        addOperationFunction(values);
      },
      onCancel() {},
    });
  };

  const deleteLine = async (line) => {
    setLoadingForm(true);
    // borrar elemento de array
    setLinesData((lineas) => lineas.filter((e) => e.lineNumber !== line));
    notification['success']({
      message: `Línea ${line} eliminada exitosamente `,
    });
    setLoadingForm(false);

    return false;
  };
  const addOperationFunction = React.useCallback(
    async (values) => {
      // setLoadingForm(true);

      if (values.status !== 0) {
        let array = lines;
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          const { totalGrossWeight, totalNetWeight, totalTareWeight } = element;
          if (
            totalGrossWeight === 0 ||
            totalNetWeight === 0 ||
            totalTareWeight === 0
          ) {
            notification['error']({
              message: `Los pesos totales de la línea ${element.number} no pueden ser 0.`,
            });
            setLoadingForm(false);
            return false;
          }
        }
      }
      values['eta'] = moment(values.eta).unix();
      values['etd'] = moment(values.etd).unix();
      values['operationLines'] = lines;
      let as = additionalServices?.map((p, i) => ({ ...p, number: i }));
      values['additionalServices'] = as;
      values['status'] = values.status ? values.status : 0;

      if (operationId) {
        values['id'] = operationId;
        if (operationId.isCompleted) {
          notification['error']({
            description: 'No puede modificar esta operación',
          });
          return;
        }
      }
      if (values.service === MARITIMO_VALUE_ID) {
        values['reservationDate'] = moment(values.reservationDate).unix();
        values['arrivalDate'] = moment(values.arrivalDate).unix();
      } else if (values.service === AEREO_VALUE_ID) {
        values['arrivalDate'] = moment(values.arrivalDate).unix();
      }

      const config = {
        method: operationId ? METHOD_PUT : METHOD_POST,
        url: `${API_URL_OPERATION}${operationId ? '/' + operationId : ''}`,
        data: values,
        token,
        showNotification: false,
      };
      fetchApi(config)
        .then((response) => {
          setLoadingForm(false);
          if (response) {
            if (response.statusCode === 200) {
              notification['success']({
                message: 'Operación exitosa',
              });
              // setPieces([]);
              // setLinesData([]);
              if (type !== 'Edit') {
                // clearFilterOperation('clear');
                setType('Edit');
                setOperationId(response.result.id);
              }
            }
          }
          if (type === 'Edit') {
            // setModalOperationIsVisible(false);
            setReloadTable(true);
          }
          // setReloadOperation(true);

          if (response.statusCode === 400) {
            notification['error']({
              message: response.description,
              description: response.result,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setReloadOperation(true);
        });
    },
    [lines, additionalServices]
  );
  return (
    <Spin spinning={loadingForm} tip="Cargando...">
      <div>
        <Row>
          <Col xs={24}>
            <OperationHeader
              service={service}
              setService={setService}
              disableAllInputs={disableAllInputs}
              handleSaveOperation={handleSaveOperation}
              validationOperationField={validationOperationField}
              showModal={showModal}
              customerContacts={customerContacts}
              generalOperationForm={generalOperationForm}
              quoteSelected={quoteSelected}
              type={type}
              disabledInputs={disabledInputs}
              setDisabledInputs={setDisabledInputs}
              clearFilterOperation={clearFilterOperation}
              clearHeader={clearHeader}
              operationId={operationId}
              hazMatAvailable={hazMatAvailable}
              routes={customerRoutes}
              clientSelectedOperation={clientSelectedOperation}
              allRoutes={allRoutes}
              setRouteSelected={setRouteSelected}
              delivered={delivered}
              statusSelected={statusSelected}
              setStatusSelected={setStatusSelected}
              statusInitial={statusInitial}
              routeCity={routeCity}
            />
            {idSelected !== null && (
              <>
                <Divider>Líneas</Divider>
                <OperationLine
                  service={service}
                  type={type}
                  quoteSelected={quoteSelected}
                  lines={lines}
                  setLines={setLines}
                  basicInformationForm={basicInformationForm}
                  linesDetailForm={linesDetailForm}
                  clearHeader={clearHeader}
                  additionalServices={additionalServices}
                  setAdditionalServices={setAdditionalServices}
                  quoteId={quoteId}
                  operationId={operationId}
                  delivered={delivered}
                />
              </>
            )}

            {showAlert && (
              <Alert
                message={textType}
                description={textDescription}
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            <Row justify="end">
              <Col md={6} xs={24}>
                <Button
                  disabled={disableAllInputs || lines.length < 1}
                  className="btn-enviar"
                  style={{ width: 170 }}
                  block
                  onClick={() => generalOperationForm.submit()}
                  icon={<SaveOutlined />}
                >
                  Guardar operación
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        // width={1000}
        width={800}
      >
        {contextModal}
      </Modal>
    </Spin>
  );
};

export default AddEditOperationForm;
