//CATALOGOS
export const CURRENCY_CATALOG_ID = 1;
export const TYPE_OF_LOAD_CATALOG_ID = 2;
export const UNIT_TYPE_CATALOG_ID = 3;
export const SERVICES_CATALOG_ID = 4;
export const COUNTRY_CATALOG_ID = 7;
export const STATE_CATALOG_ID = 10;
export const CITY_CATALOG_ID = 11;
export const ROLES_CONTACTO_CATALOG_ID = 12;
export const OPERACIONES_CATALOG_ID = 13;
export const PORT_CATALOG_ID = 16;
export const HAZMAT_CATALOG_ID = 17;
export const TYPE_OF_SERVICE_CATALOG_ID = 18;
export const PACKAGING_UNIT_CATALOG_ID = 19;
export const CERTIFICATIONS_CATALOG_ID = 20;
export const TYPE_OF_EQUIPMENT_CATALOG_ID = 21;
export const MEASUREMENT_UNIT_CATALOG_ID = 22;
export const AIRPORT_CATALOG_ID = 24;
export const PAYMENT_TERMS_CATALOG_ID = 25;
export const FRECUENCY_CATALOG_ID = 26;
export const INCOTERM_CATALOG_ID = 29;
export const INSURANCE_CATALOG_ID = 30;
export const CONTAINER_CATALOG_ID = 31;
export const MODALITY_CATALOG_ID = 38;

//VALUES
export const TERRESTRE_VALUE_ID = 39;
export const MARITIMO_VALUE_ID = 40;
export const AEREO_VALUE_ID = 41;
export const ADUANA_VALUE_ID = 100;
export const HAZMAT_VALUE_ID = 7;
export const CANADA_VALUE_ID = 31;
export const DIMENSIONES_VALUE_ID = 192;
export const PESO_VALUE_ID = 195;
export const PIEZAS_VALUE_ID = 196;
export const FLETE_VALUE_ID = 197;
