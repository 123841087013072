import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { CANADA_VALUE_ID } from '../../../../../../helpers/constants';

const Location = ({
  onChangeCountryOrigin,
  countries,
  onChangeState,
  states,
  cities,
  disableAllInputs,
}) => {
  return (
    <div className="location-client">
      <Row gutter={16} className="table-company">
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="País"
            name="country"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese País',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una opción"
              onChange={onChangeCountryOrigin}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Estado:"
            name="state"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese Estado',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChangeState}
              disabled={disableAllInputs}
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Ciudad:"
            name="city"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese Ciudad',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={16}>
          <Form.Item
            label="Dirección:"
            name="address"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese Dirección',
              },
            ]}
          >
            <Input
              placeholder="Ingresa tu información"
              disabled={disableAllInputs}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="CP:"
            name="zipCode"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un código postal',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (getFieldValue('originCountry') === CANADA_VALUE_ID) {
                    if (
                      value &&
                      value.toString().length >= 3 &&
                      value.toString().length <= 6
                    ) {
                      return Promise.resolve();
                    }
                  } else {
                    if (
                      value === undefined ||
                      value === '' ||
                      (value.toString().length === 5 && !isNaN(value))
                    ) {
                      return Promise.resolve();
                    }
                  }
                  return Promise.reject(
                    'Por favor ingrese un código postal válido'
                  );
                },
              }),
            ]}
          >
            <Input
              placeholder="Ingresa tu información"
              disabled={disableAllInputs}
              maxLength={5}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Location;
