export const bingKey =
  'AvMMCjv1m2WG02X0xGvdFAG0YUpIkf-t_f2WAU0X_WMDUm-ZrV9ZoQPxgM5FvFx0';

//QA
export const basePath = 'https://portales.planbsys.com/gpmetwebportalQA/api';
//export const basePath = 'https://localhost:44306/api';

// export const basePath = 'http://52.168.29.115/gpmetwebportalQA/api';

//GPMET
// export const basePath = 'https://webapi.gpmetlogistics.com/api';

//PRUEBA
// export const basePath = 'https://portales.planbsys.com/gpmetwebportal/api';
