import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  formatMoney,
  formatPhoneNumber,
  nameMonth,
} from '../../../../utils/general';
// import TopImage from '../../../../assets/img/png/top-pagina.png';
// import BottomImage from '../../../../assets/img/png/pie-pagina.png';
import Fondo from '../../../../assets/img/png/fondopdf.png';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';

// Create styles
const styles = StyleSheet.create({
  page: {
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35,
  },
  containerTop: {
    marginTop: 150,
  },
  containerBottom: {
    marginBottom: 100,
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  section: {
    margin: 5,
    padding: 10,
    marginTop: 0,
    // flexGrow: 1,
  },
  containerRight: {
    margin: 10,
    marginTop: 0,
  },
  textClient: {
    marginTop: 0,
    fontSize: 13,
  },
  marginTop: {
    marginTop: 20,
    fontSize: 13,
  },
  rowTable: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionTable: {
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: 200,
    borderWidth: 1,
    borderColor: '#000',
  },
  titleTable: {
    fontSize: 11,
    textAlign: 'center',
    fontWeight: 'extrabold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  titleTableSmall: {
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'extrabold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  infoTable: {
    textAlign: 'center',
    fontSize: 12,
    marginBottom: 5,
  },
  rowTableInfo: {
    flexDirection: 'row',
  },
  note: {
    fontStyle: 'italic',
    fontSize: 12,
    marginTop: 5,
  },
  text: {
    fontSize: 13,
  },
  margingText: {
    marginTop: 5,
  },
  containerTable: {
    marginTop: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

// const quoteTest = {
//   id: 11,
//   endDate: 1646097465,
//   comments: 'test quote',
//   customerName: 'Avalanche',
//   totalSale: 600,
//   quoteLines: [
//     {
//       id: 17,
//       lineNumber: 1,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 123456780,
//       currency: null,
//       totalWeight: 110,
//       totalCBM: 428340,
//       numberPieces: 22,
//     },
//     {
//       id: 18,
//       lineNumber: 2,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 54670,
//       currency: null,
//       totalWeight: 135,
//       totalCBM: 1224,
//       numberPieces: 3,
//     },
//     {
//       id: 19,
//       lineNumber: 3,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 200,
//       currency: null,
//       totalWeight: 1,
//       totalCBM: 1,
//       numberPieces: 1,
//     },
//     {
//       id: 20,
//       lineNumber: 4,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 40078,
//       currency: null,
//       totalWeight: 11,
//       totalCBM: 1,
//       numberPieces: 1,
//     },
//     {
//       id: 17,
//       lineNumber: 1,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 6777.8,
//       currency: null,
//       totalWeight: 110,
//       totalCBM: 428340,
//       numberPieces: 22,
//     },
//     {
//       id: 1834.56,
//       lineNumber: 2,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 0,
//       currency: null,
//       totalWeight: 135,
//       totalCBM: 1224,
//       numberPieces: 3,
//     },
//     {
//       id: 19,
//       lineNumber: 3,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 200,
//       currency: null,
//       totalWeight: 1,
//       totalCBM: 1,
//       numberPieces: 1,
//     },
//     {
//       id: 20,
//       lineNumber: 4,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 400,
//       currency: null,
//       totalWeight: 11,
//       totalCBM: 1,
//       numberPieces: 1,
//     },
//     {
//       id: 17,
//       lineNumber: 1,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 0,
//       currency: null,
//       totalWeight: 110,
//       totalCBM: 428340,
//       numberPieces: 22,
//     },
//     {
//       id: 18,
//       lineNumber: 2,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 0,
//       currency: null,
//       totalWeight: 135,
//       totalCBM: 1224,
//       numberPieces: 3,
//     },
//     {
//       id: 19,
//       lineNumber: 3,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 200,
//       currency: null,
//       totalWeight: 1,
//       totalCBM: 1,
//       numberPieces: 1,
//     },
//     {
//       id: 20,
//       lineNumber: 4,
//       service: 'Terrestre',
//       typeOfService: 'LTL',
//       unitType: 'CAJA SECA 53 FT',
//       originCity: 'Aguascalientes',
//       destinationCity: 'Aguascalientes',
//       sale: 400,
//       currency: null,
//       totalWeight: 11,
//       totalCBM: 1,
//       numberPieces: 1,
//     },
//   ],
// };

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();
const currentDay = new Date().getDate();

const dateString = `${currentDay} DE ${nameMonth(
  currentMonth
).toUpperCase()} DEL ${currentYear}`;

export const QuotePdf = ({ quote }) => {
  const [validDate, setValidDate] = useState(null);

  useEffect(() => {
    if (quote) {
      const dayValid = new Date(quote.endDate * 1000);
      const day = dayValid.toLocaleString('es-MX', { day: 'numeric' });
      const month = dayValid.toLocaleString('es-MX', { month: 'long' });
      const year = dayValid.toLocaleString('es-MX', { year: 'numeric' });
      setValidDate(`${day} ${month} ${year}`);
    }
  }, [quote]);

  if (!quote) {
    return <View />;
  }
  return (
    <Document>
      <Page size="LETTER" style={styles.page} wrap>
        {/* <Image src={TopImage} /> */}
        <Image src={Fondo} style={styles.pageBackground} fixed />
        <View style={styles.containerTop} fixed />
        <View style={styles.containerRight}>
          <Text style={{ textAlign: 'right', fontSize: 13 }}>{dateString}</Text>
          <Text style={{ textAlign: 'right', fontSize: 13, marginTop: 5 }}>
            {`COTIZACIÓN NO. - ${
              quote.quoteIdStr ? quote.quoteIdStr : quote.id
            }`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textClient}>{quote.customerName}</Text>
          <Text style={[styles.marginTop]}>
            Por medio de la presente y agradeciendo de antemano su confianza,
            ofrezco la siguiente cotización para su empresa
          </Text>
          <Text style={[styles.marginTop, { marginBottom: 5 }]}>
            CARACTERÍSTICAS
          </Text>
          {quote.quoteLines.map((line, index) => (
            <Text
              key={`line-${index}`}
              style={[styles.marginTop, { marginTop: 4 }]}
            >
              L{index + 1} PIEZAS - {line.numberPieces} PESO -{' '}
              {line.totalWeight} KG - CBM:
              {line.totalCBM} M2
            </Text>
          ))}

          <View style={styles.containerTable}>
            <Table data={quote.quoteLines}>
              <TableHeader textAlign={'center'}>
                <TableCell style={styles.titleTable} weighting={0.5}>
                  LÍNEA
                </TableCell>
                <TableCell style={styles.titleTable}>SERVICIO</TableCell>
                <TableCell style={styles.titleTable}>TIPO</TableCell>
                <TableCell style={styles.titleTable}>
                  ORIGEN - DESTINO
                </TableCell>
                <TableCell style={styles.titleTable}>UNIDAD</TableCell>
                <TableCell style={styles.titleTable}>PRECIO-SUBTOTAL</TableCell>
                {/* <TableCell style={styles.titleTable}>MONEDA</TableCell> */}
              </TableHeader>

              <TableBody textAlign={'center'}>
                <DataTableCell
                  weighting={0.5}
                  getContent={(r, index) => r.lineNumber}
                  style={styles.titleTable}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.service}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.typeOfService}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.originCity + ' - ' + r.destinationCity}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.unitType}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) =>
                    `$ ${formatMoney(r.sale)} ${r.currency ? r.currency : ''}`
                  }
                />
              </TableBody>
            </Table>
          </View>
          {quote.quoteAS.length >= 1 && (
            <>
              <Text style={[styles.marginTop, { marginBottom: 5 }]}>
                Servicios Adicionales
              </Text>

              <View style={styles.containerTable}>
                <Table data={quote.quoteAS}>
                  <TableHeader textAlign={'center'}>
                    <TableCell style={styles.titleTable} weighting={0.5}>
                      No
                    </TableCell>
                    <TableCell style={styles.titleTable}>SERVICIO</TableCell>
                    <TableCell style={styles.titleTable}>TIPO</TableCell>
                    <TableCell style={styles.titleTableSmall}>
                      SERVICIO ADICIONAL
                    </TableCell>
                    <TableCell style={styles.titleTable}>PROVEEDOR</TableCell>
                    <TableCell style={styles.titleTable}>COSTO</TableCell>
                    {/* <TableCell style={styles.titleTable}>MONEDA</TableCell> */}
                  </TableHeader>

                  <TableBody textAlign={'center'}>
                    <DataTableCell
                      weighting={0.5}
                      getContent={(r, index) => r.number + 1}
                      style={styles.titleTable}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.service}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.typeOfService}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.additionalService}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.supplierName}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => `$ ${formatMoney(r.cost)}`}
                    />
                  </TableBody>
                </Table>
              </View>
            </>
          )}

          <View style={styles.containerRight}>
            <Text style={{ textAlign: 'right', fontSize: 13, marginTop: 5 }}>
              {`TOTAL  $ ${formatMoney(quote.totalSale)} ${
                quote.quoteLines.length > 0
                  ? quote.quoteLines[0]['currency'] || ''
                  : ''
              }`}
            </Text>
          </View>

          {/* <View style={styles.rowTable}>
            <View style={styles.sectionTable}>
              <Text style={styles.titleTable}>TOTAL</Text>
            </View>
            <View style={styles.sectionTable}>
              <Text style={styles.infoTable}>
                $ {formatMoney(quote.totalSale)}{' '}
                {quote.quoteLines.length > 0
                  ? quote.quoteLines[0]['currency']
                  : ''}
              </Text>
            </View>
          </View> */}

          <Text
            style={[
              styles.marginTop,
              { fontSize: 14, fontWeight: 'bold', marginTop: 30 },
            ]}
          >
            NOTAS IMPORTANTES.
          </Text>
          <Text style={styles.note}>{quote.comments}</Text>
          <Text style={[styles.note, { fontSize: 12, fontWeight: 'bold' }]}>
            *VIGENCIA {validDate}{' '}
          </Text>

          <Text style={styles.marginTop}>
            Esperando que la cotización sea de su agrado quedo a sus órdenes.
          </Text>
          <Text style={[styles.text, styles.margingText]}>
            {quote.user.name} {quote.user.lastName}
          </Text>
          <Text style={[styles.text, styles.margingText]}>
            {`TEL. ${
              quote.user.phoneNumber
                ? formatPhoneNumber(quote.user.phoneNumber)
                : ''
            }`}
          </Text>
        </View>
        <View style={styles.containerBottom} fixed />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        {/* <Image src={BottomImage} /> */}
      </Page>
    </Document>
  );
};
