import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import { getFileInvoice } from '../../../../api/billing';
import { DownloadOutlined } from '@ant-design/icons';
import { METHOD_GET } from '../../../../utils/constants';
import { fetchBlob } from '../../../../helpers/fetch';
const FileForm = ({ setModalIsVisible, type, reference }) => {
  const token = getAccessTokenApi();
  const [dataUrl, setDataUrl] = useState([]);
  const [typeFile, setTypeFile] = useState([]);

  useEffect(() => {
    let data = {};
    if (type === 'pdf' || type === 'xml') {
      data = {
        reference: reference,
        documentType: 'APInvoice',
        extensionFilter: [type],
      };
    } else if (type === 'cfdi') {
      data = {
        reference: reference,
        documentType: 'APInvoiceAdd',
      };
    }

    getFileInvoice(token, data).then((response) => {
      if (response.statusCode === 200) {
        setDataUrl(response.result[0]);
        // setDataUrl(response?.result[0]?.stringFileContent);
        // setTypeFile(response?.result[0]?.extension);
      }
    });
  }, [token, reference, type]);

  const downloadFile = async (data) => {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const config = {
      method: METHOD_GET,
      url: `Documents/DownloadAPInvoice?DocumentId=${data.id}`,
      data: null,
      token,
      showNotificationError: false,
    };
    fetchBlob(config)
      .then((response) => {
        let url = window.URL.createObjectURL(response);
        a.href = url;
        a.download = data.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {dataUrl !== undefined && (
        <Button
          icon={<DownloadOutlined />}
          className="btn-download"
          // href={'data:image/png;base64,' + dataUrl}
          // download={'Archivo_facturacion.' + typeFile}
          onClick={() => downloadFile(dataUrl)}
          style={{ width: 170 }}
        >
          Descargar archivo
        </Button>
      )}
      {dataUrl === undefined && <p>No hay archivo</p>}
    </div>
  );
};

export default FileForm;
