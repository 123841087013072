import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { METHOD_GET } from '../../../../../../utils/constants';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { fetchApi } from '../../../../../../helpers/fetch';
import { API_URL_QUOTE_LINES } from '../../../../../../helpers/urls';
import moment from 'moment';

const QuotesTable = ({
  pickQuote,
  setModalIsVisibleQuote,
  setQuoteSelected,
  setIdSelected,
  filterQuote,
  form: formOperation,
  routeSelected,
  basicInformationForm,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [quotes, setQuotes] = useState([]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log(selectedRows, 'selectedRows');
    setSelectedRowKeys(selectedRowKeys);
    if (pickQuote) {
      setQuoteSelected(selectedRows[0].quoteLine);
      setIdSelected(selectedRowKeys.id);
      formOperation.setFieldsValue({
        quoteId: selectedRows[0].quoteId,
        quoteIdStr: selectedRows[0].quoteIdStr,
        quoteLineId: selectedRows[0].quoteLine.id,
        originAddress: selectedRows[0].quoteLine.originAddress,
        destinationAddress: selectedRows[0].quoteLine.destinationAddress,
        originZipCode: selectedRows[0].quoteLine.originZipCode,
        destinationZipCode: selectedRows[0].quoteLine.destinationZipCode,
        originPort: selectedRows[0].quoteLine.originPort,
        originAirport: selectedRows[0].quoteLine.originAirport,
        destinationPort: selectedRows[0].quoteLine.destinationPort,
        destinationAirport: selectedRows[0].quoteLine.destinationAirport,
      });
      basicInformationForm.setFieldsValue({
        equipmentType: selectedRows[0].unitTypeId,
        modalityId: selectedRows[0].quoteLine.modalityId,
      });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: pickQuote ? 'radio' : 'checkbox',
    onChange: onSelectChange,
  };
  useEffect(() => {
    setIsLoadingTable(true);
    const filters = `?CustomerID=${filterQuote.customerId}&Origin=${
      filterQuote.originCity
    }&Destination=${filterQuote.destinationCity}&Service=${
      filterQuote.service
    }&TypeOfService=${filterQuote.typeOfService}&LoadType=${
      filterQuote.loadType
    }&etd=${moment(filterQuote.etd).unix()}&routeId=${
      routeSelected ? routeSelected : ''
    }
    `;
    const config = {
      method: METHOD_GET,
      url: `${API_URL_QUOTE_LINES}${filters}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response');
        if (response.statusCode === 200) {
          const allQuotes = response.result;
          allQuotes.map((quote) => (quote['key'] = quote.id));
          if (pickQuote) {
            setQuotes(allQuotes);
            // const completeQuotes = allQuotes.filter(
            //   (quote) => quote.isCompleted
            // );
            // setQuotes(completeQuotes);
          } else {
            setQuotes(allQuotes);
          }
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, token, filterQuote]);

  const columns = [
    {
      title: 'No. / Id cotización',
      dataIndex: 'quoteId',
      key: 'quoteId',
      width: 80,
      align: 'center',
      render: (id, quote) => (
        <span>{quote.quoteIdStr ? quote.quoteIdStr : id}</span>
      ),
    },
    {
      title: 'No. Línea',
      dataIndex: 'lineNumber',
      key: 'lineNumber',
      width: 80,
      align: 'center',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierStr',
      key: 'supplierStr',
      width: 80,
      align: 'center',
    },
    {
      title: 'Vigencia',
      children: [
        {
          title: 'Fecha inicio',
          dataIndex: 'startDate',
          key: 'startDate',
          width: 100,
          align: 'center',
          render: (startDate) => (
            <span>{`${moment.unix(startDate).format('DD/MM/YYYY')}`}</span>
          ),
        },
        {
          title: 'Fecha final',
          dataIndex: 'endDate',
          key: 'endDate',
          width: 100,
          align: 'center',
          render: (endDate) => (
            <span>{`${moment.unix(endDate).format('DD/MM/YYYY')}`}</span>
          ),
        },
      ],
    },
    // {
    //   title: 'Ciudad origen',
    //   dataIndex: 'originCity',
    //   key: 'originCity',
    //   width: 80,
    //   align: 'center',
    // },
    // {
    //   title: 'Ciudad destino',
    //   dataIndex: 'destinationCity',
    //   key: 'destinationCity',
    //   width: 80,
    //   align: 'center',
    // },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfService',
      key: 'typeOfService',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tipo de carga',
      dataIndex: 'loadType',
      key: 'loadType',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tipo de equipo',
      dataIndex: 'unitType',
      key: 'unitType',
      width: 80,
      align: 'center',
    },
  ];

  return (
    <div>
      <Table
        dataSource={quotes}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className="table-striped-rows"
        rowSelection={rowSelection}
        loading={isLoadingTable || reloadTable}
        footer={() => {
          return (
            <FooterTable
              pickQuote={pickQuote}
              setModalIsVisibleQuote={setModalIsVisibleQuote}
              // clearSupplier={clearSupplier}
            />
          );
        }}
      />
    </div>
  );
};

export default QuotesTable;

function FooterTable({ pickQuote, setModalIsVisibleQuote }) {
  return (
    <div className="footer-table">
      {pickQuote && (
        <>
          <Button
            type="primary"
            onClick={() => {
              setModalIsVisibleQuote(false);
            }}
          >
            Aceptar
          </Button>
        </>
      )}
    </div>
  );
}
