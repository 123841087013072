import { types } from '../../types/types';

export const catalogQouteReducer = (state, action) => {
  switch (action.type) {
    case types.getTypeOfLoad:
      return {
        ...state,
        typeOfLoad: action.payload,
      };

    case types.getOperations:
      return {
        ...state,
        operations: action.payload,
      };

    case types.getHazMat:
      return {
        ...state,
        hazMat: action.payload,
      };

    case types.getTypeOfService:
      return {
        ...state,
        typeOfService: action.payload,
      };
    case types.getEquipmentType:
      return {
        ...state,
        typeOfEquipment: action.payload,
      };
    case types.getUnity:
      return {
        ...state,
        unit: action.payload,
      };
    case types.getMeasurementUnit:
      return {
        ...state,
        measurementUnit: action.payload,
      };
    case types.getCurrency:
      return {
        ...state,
        currency: action.payload,
      };
    case types.getPackagingUnit:
      return {
        ...state,
        packaging: action.payload,
      };

    case types.getFrecuency:
      return {
        ...state,
        frecuency: action.payload,
      };
    case types.getIncoterm:
      return {
        ...state,
        incoterm: action.payload,
      };
    case types.getInsurance:
      return {
        ...state,
        insurance: action.payload,
      };

    case types.getContainer:
      return {
        ...state,
        container: action.payload,
      };
    case types.getModality:
      return {
        ...state,
        modality: action.payload,
      };
    default:
      return state;
  }
};
