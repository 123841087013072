import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Select,
  notification,
} from 'antd';
import { addApiInvoice } from '../../../../api/billing';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { useHistory } from 'react-router-dom';
import './AddBillingForm.scss';
import TextArea from 'antd/lib/input/TextArea';
import { getAllInvoiceSeriesApi } from '../../../../api/series';
import { formatMoney } from '../../../../utils/general';
import { METHOD_POST } from '../../../../utils/constants';
import { fetchApi } from '../../../../helpers/fetch';

const AddBillingForm = ({
  setModalVisible,
  billingFields,
  fileXml,
  filePdf,
  fileCfdi,
  nameXml,
  operationId,
  setReloadTable,
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();
  let history = useHistory();
  // const [invoiceSeries, setInvoiceSeries] = useState([]);

  useEffect(() => {
    const token = getAccessTokenApi();
    form.setFieldsValue({
      operationIdStr: operationId,
    });
    getAllInvoiceSeriesApi(token).then((response) => {
      // console.log(response, 'invoice series');
      if (response) {
        if (response.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado',
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return;
        }
        if (response.statusCode === 200) {
          // let invseries = response.result.map((s) => ({
          //     value: s.id,
          //     label: s.serie,
          // }));
          // setInvoiceSeries(invseries);
        }
      }
    });
  }, []);

  const addDocFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    const sd = billingFields.fechaDocumento.split('/');
    data['invoiceNum'] = billingFields.folio;
    data['documentType'] = billingFields.tipoDocumento;
    data['documentDate'] = `${sd[2]}/${sd[1]}/${sd[0]}`;
    data['total'] = parseFloat(billingFields.importe);
    data['currencyId'] = billingFields.moneda;
    data['cfdiFileXML'] = fileXml.split(',')[1];
    data['cfdiFilePdf'] = filePdf.split(',')[1];
    data['status'] = billingFields.estatus;
    data['serie'] = billingFields.serie;
    data['subTotal'] = parseFloat(billingFields.subTotal);
    data['taxAmount'] = parseFloat(billingFields.taxAmount);
    data['taxRate'] = parseFloat(billingFields.taxRate);

    if (fileCfdi.length !== 0) {
      // data['cfdiAcuse'] = fileCfdi.split(',')[1] ?? [];
      data['cfdiAcuse'] = {
        extension: fileCfdi.extension,
        stringFilecontent: fileCfdi.stringFilecontent,
        contentType: fileCfdi.contentType,
        fileName: fileCfdi.fileName,
      };
    }
    const config = {
      method: METHOD_POST,
      url: `APInvoice/PostAPInvoice`,
      data: data,
      token,
      showNotificationError: false,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          notification['success']({
            message: response.description,
          });
        } else {
          notification['error']({
            message: response.description,
          });
        }
        if (response.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado.',
          });
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setModalVisible(false);
        setLoadingForm(false);
        setReloadTable(true);
      });
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form
        name="add-doc"
        layout="vertical"
        form={form}
        onFinish={addDocFunction}
      >
        <div>
          <Row xs={24}>
            <Col
              xs={24}
              md={billingFields.tipoDocumento === 'I' ? 24 : 14}
              className="container-billing-gray"
              style={{ marginBottom: 10 }}
            >
              <Row>
                <Col xs={10}>
                  <p>Nombre del archivo</p>
                </Col>
                <Col xs={12}>
                  <p>{nameXml}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Emisor</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.emisor}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Receptor</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.receptor}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Tipo de documento</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.tipoDocumento}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Fecha de documento</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.fechaDocumento}</p>
                </Col>
              </Row>
              {/* <Row>
                                <Col xs={10}>
                                    <p>Serie</p>
                                </Col>
                                <Col xs={12}>
                                    <p>{billingFields.serie}</p>
                                </Col>
                            </Row> */}
              <Row>
                <Col xs={10}>
                  <p>Folio</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.folio}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Moneda</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.moneda}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Importe total</p>
                </Col>
                <Col xs={12}>
                  <p>${formatMoney(parseFloat(billingFields.importe))}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Validación fiscal</p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.estatus}</p>
                </Col>
              </Row>
              <Row xs={10}>
                <Col xs={10}>
                  <p>Serie: </p>
                </Col>
                <Col xs={12}>
                  <p>{billingFields.serie}</p>
                  {/* <Form.Item
                                        name="invoiceSerieId"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Por favor seleccione una serie',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Selecciona una opción">
                                            {invoiceSeries?.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                </Col>
              </Row>
              {/* {billingFields.tipoDocumento === 'I' && (
                <Row xs={10}>
                  <Col xs={10}>
                    <p>No de orden de compra</p>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="purchaseOrder"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un número de compra',
                        },
                      ]}
                    >
                      <Input placeholder="12589" />
                    </Form.Item>
                  </Col>
                </Row>
              )} */}
              {/* {billingFields.tipoDocumento === 'I' && ( */}
              <Row xs={10}>
                <Col xs={10}>
                  <p>Id de operación</p>
                </Col>
                <Col xs={12}>
                  <Form.Item name="operationIdStr">
                    <Input placeholder="12589" disabled />
                  </Form.Item>
                </Col>
              </Row>
              {/* )} */}

              {billingFields.tipoDocumento === 'E' && (
                <Row xs={10}>
                  <Col xs={10}>
                    <p>Categoría:</p>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="categoryId"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor seleccione una categoría',
                        },
                      ]}
                    >
                      <Select placeholder="Categoria">
                        <Select.Option key="0" value="AJP">
                          Ajuste de Precio
                        </Select.Option>
                        <Select.Option key="1" value="EEC">
                          Error en conceptos
                        </Select.Option>
                        <Select.Option key="2" value="FDP">
                          Factura duplicada
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
            {billingFields.tipoDocumento === 'E' && (
              <Col xs={24} md={9} style={{ margin: 10 }}>
                <Form.Item label="Observaciones:" name="observations">
                  <TextArea style={{ height: 300 }}></TextArea>
                </Form.Item>
                <Form.Item
                  label="Folio relacionado:"
                  name="reference"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese factura',
                    },
                  ]}
                >
                  <Input placeholder="12589" />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row xs={24} className="table-company">
            <Col xs={20}>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="btn-enviar"
                      block
                      style={{ width: 150 }}
                    >
                      Cargar documento
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddBillingForm;
