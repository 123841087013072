import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal as ModalAntd,
  Row,
  Spin,
  Upload,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  ROL_FINANCIAL,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_SUPPLIER,
} from '../../../../utils/constants';
import { fetchApi, fetchBlob } from '../../../../helpers/fetch';
import moment from 'moment';
import { API_URL_PAYMENTS } from '../../../../helpers/urls';
import {
  ExclamationCircleOutlined,
  FileAddOutlined,
  UploadOutlined,
} from '@ant-design/icons';
// import AddDocumentsPagoForm from '../Documents/AddDocumentsPagoForm';
import toBase64 from '../../../../utils/toBase64';
import useUser from '../../../../hooks/useUser';

const PaymentInvoice = (props) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const [filesListImages, setFileListImages] = useState([]);
  const { person } = useUser();
  const {
    setModalIsVisible,
    invoiceId,
    paymentId,
    setReloadPayments,
    type,
    typeInvoice,
  } = props;
  const [invoicePaymentForm] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const extraImagesChange = (info) => {
    let fileList = [...info.fileList];
    if (fileList.length > 5) {
      notification['warn']({
        message: 'No puede subir más de 5 documentos',
      });
    }
    setFileListImages(fileList);
  };

  const propsUpload = {
    onRemove: async (file) => {
      if (file.response?.status === 'success') {
        // showDeleteConfirm(file);
        return false;
      } else {
        const index = filesListImages.indexOf(file);
        const newFileListImage = filesListImages.slice();
        newFileListImage.splice(index, 1);
        setFileListImages(newFileListImage);
        return true;
      }
    },
    beforeUpload: (file) => {
      return false;
    },

    onChange: extraImagesChange,
    //type === 'watch'

    showUploadList: {
      showDownloadIcon:
        props.type === 'watch' &&
        [ROL_ADMIN, ROL_LAYOUT, ROL_SUPPLIER, ROL_FINANCIAL].some((r) =>
          person.roles.includes(r)
        ),

      showRemoveIcon:
        props.type !== 'watch' &&
        [ROL_ADMIN, ROL_LAYOUT, ROL_FINANCIAL].some((r) =>
          person.roles.includes(r)
        ),
    },

    onDownload: async (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url:
          typeInvoice === 'ap'
            ? `Payments/${props.paymentId}/DownloadFile/${data.id}`
            : `ARPayments/${props.paymentId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onPreview: (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url:
          typeInvoice === 'ap'
            ? `Payments/${props.paymentId}/DownloadFile/${data.id}`
            : `ARPayments/${props.paymentId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };

  const uploadExtraImageServe = () => {
    setLoadingImage(true);
    setLoadingForm(true);
    if (filesListImages.length > 0) {
      let imgs = [];
      filesListImages.forEach((img) => {
        if (img.status === 'done') {
          imgs.push({
            extension: img.extension,
            base64Promise: '',
            stringFileContent: img.stringFileContent,
            fileName: img.name,
            contentType: img.type,
          });
        } else {
          imgs.push({
            extension: img.originFileObj.type.split('/')[1],
            base64Promise: toBase64(img.originFileObj),
            stringFileContent: '',
            fileName: img.name,
            contentType: img.type,
          });
        }
      });

      Promise.all(imgs.map((img) => img.base64Promise)).then((values) => {
        values.forEach((value, index) => {
          if (value) {
            imgs[index].stringFileContent = value.split(',')[1];
          }
        });
      });
      const data = {
        documents: imgs,
      };
      setLoadingImage(false);
      setLoadingForm(false);
      return data;
    }
  };

  useEffect(() => {
    //edit or watch
    if (paymentId) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_PAYMENTS}/${paymentId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const date = moment.unix(response.result.paymentDate);
            const invoice = {
              paymentDate: date,
              total: response.result.total,
            };
            invoicePaymentForm.setFieldsValue(invoice);
            setFileListImages(
              response.result.paymentFiles.map((paymentFile, index) => {
                return {
                  uid: index,
                  name: paymentFile.fileName,
                  extension: paymentFile.extension,
                  stringFileContent: paymentFile.stringFileContent,
                  status: 'done',
                  url: paymentFile.fileLocation,
                  id: paymentFile.id,
                  thumbUrl: paymentFile.fileLocation,
                };
              })
            );
          }
          setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingForm(false);
        });
    }
  }, [paymentId, token, invoicePaymentForm]);

  const handleSaveInvoicePayment = (values) => {
    var dataImg = uploadExtraImageServe();

    if (filesListImages.length === 0) {
      notification['error']({
        message: 'Debes agregar un documento',
      });
      setLoadingImage(false);
      setLoadingForm(false);
    } else {
      confirm({
        title: !paymentId ? 'Guardar pago' : 'Editar pago',
        icon: <ExclamationCircleOutlined />,
        content: !paymentId
          ? '¿Estás seguro que deseas guardar pago?'
          : '¿Estás seguro que deseas editar pago?',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          // return;
          addPaymentInvoice(values, dataImg);
        },
        onCancel() {},
      });
    }
  };
  const addPaymentInvoice = (values, dataImg) => {
    setLoadingForm(true);
    values['paymentDate'] = moment(values.paymentDate).unix();
    if (typeInvoice === 'ap') {
      values['APInvoiceId'] = invoiceId;
    } else if (typeInvoice === 'ar') {
      values['ARInvoiceId'] = parseInt(invoiceId);
    }
    values['PaymentFiles'] = dataImg.documents;

    if (paymentId) {
      values['id'] = paymentId;
    }
    for (let i = 0; i < values.PaymentFiles.length; i++) {
      const e = values.PaymentFiles[i];
      if (e.extension === '') {
        notification['error']({
          message: `Error, el archivo ${e.fileName} esta dañado.`,
        });
        setLoadingImage(false);
        setLoadingForm(false);
        return;
      }
    }
    const config = {
      method: paymentId ? METHOD_PUT : METHOD_POST,
      url:
        typeInvoice === 'ap'
          ? `${API_URL_PAYMENTS}${paymentId ? '/' + paymentId : ''}`
          : `ARPayments${paymentId ? '/' + paymentId : ''}`,
      data: values,
      token,
      showNotification: true,
    };
    fetchApi(config)
      .then((response) => {
        setLoadingForm(false);
        setModalIsVisible(false);
        setReloadPayments(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  return (
    <div>
      <Form
        name="add-payment"
        layout="vertical"
        form={invoicePaymentForm}
        onFinish={handleSaveInvoicePayment}
        // onFinishFailed={validationOperationField}
      >
        <Spin spinning={loadingForm} tip="Cargando...">
          <Row gutter={16}>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Fecha de pago:"
                name="paymentDate"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese fecha de factura',
                  },
                ]}
              >
                <DatePicker
                  format={'DD/MM/YYYY'}
                  disabledDate={disabledDate}
                  disabled={type === 'watch'}
                  style={{ width: '100%' }}
                  // disabled={disableAllInputs}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Total a pagar:"
                name="total"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese número de factura',
                  },
                ]}
              >
                <InputNumber
                  placeholder="Ingrese información"
                  disabled={type === 'watch'}
                  style={{ width: '100%' }}
                  min={1}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item>
                <Upload
                  {...propsUpload}
                  // accept="image/png,image/jpg"
                  multiple
                  listType="picture"
                  fileList={filesListImages}
                  defaultFileList={filesListImages}
                >
                  {[ROL_ADMIN, ROL_LAYOUT, ROL_FINANCIAL].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      style={{ width: 250, marginLeft: 0 }}
                      className="btn-download"
                      icon={<UploadOutlined />}
                      disabled={type === 'watch'}
                    >
                      Comprobante de pago
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Spin>

        <Row justify="end">
          <Col>
            <Form.Item>
              <Button
                className="btn-enviar"
                block
                disabled={type === 'watch'}
                htmlType="submit"
                icon={<FileAddOutlined />}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PaymentInvoice;
