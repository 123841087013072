import React, { useState } from 'react';
import { Row, Col, Spin, Form, Button, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import toBase64 from '../../../../utils/toBase64';
import { messageError } from '../../../../utils/general';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { addDocumentsClarificationApi } from '../../../../api/documents';

const AddDocumentsForm = ({
  setModalIsVisible,
  noteId,
  setReloadDocuments,
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();

  const [filesListImages, setFileListImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);

  const extraImagesChange = (info) => {
    let fileList = [...info.fileList];

    if (fileList.length > 5) {
      notification['warn']({
        message: 'No puede subir más de 5 documentos',
      });
    }

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(0, 5); //toma solo las 2 primeras
    // fileList = fileList.slice(-2); // remplaza las pasada y siempre son 2

    // 2. Read from response and show file link
    // fileList = fileList.map((file) => {
    //     if (file.response) {
    //         // Component will show file.url as link
    //         file.url = file.response.url;
    //     }
    //     return file;
    // });
    setFileListImages(fileList);
  };
  const propsUpload = {
    onRemove: async (file) => {
      if (file.response?.status === 'success') {
        // showDeleteConfirm(file);
        return false;
      } else {
        const index = filesListImages.indexOf(file);
        const newFileListImage = filesListImages.slice();
        newFileListImage.splice(index, 1);
        setFileListImages(newFileListImage);
        return true;
      }
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: extraImagesChange,
  };

  const uploadExtraImageServe = async () => {
    setLoadingImage(true);
    setLoadingForm(true);

    if (filesListImages.length === 0) {
      notification['error']({
        message: 'Debes agregar un documento',
      });
      setLoadingImage(false);
      setLoadingForm(false);
    }

    if (filesListImages.length > 0) {
      let promesas = [];
      let imgs = [];
      filesListImages.forEach((img) =>
        img.type === 'image/jpeg'
          ? imgs.push({
              extension: img.type.substr(img.type.indexOf('/') + 1),
              stringFileContent: img.thumbUrl.substr(
                img.thumbUrl.indexOf(',') + 1
              ),
              fileName: img.name,
            })
          : promesas.push({
              type: '1',
              extension: img.type.substr(img.type.indexOf('/') + 1),
              stringFileContent: toBase64(img.originFileObj),
              fileName: img.name,
            })
      );
      let res = await Promise.all(promesas.map((p) => p.stringFileContent));
      let resultado = [
        ...imgs,
        ...promesas.map((r, i) => ({
          ...r,
          stringFileContent: res[i].split(',')[1],
        })),
      ];
      const data = {
        NoteDetailId: Number.isInteger(noteId) ? noteId.toString() : noteId,
        Documents: resultado,
        DocumentType: 'NoteDetail',
      };

      const result = await addDocumentsClarificationApi(token, data);

      // console.log(result, 'Result');
      if (result === undefined) {
        notification['error']({
          message: messageError(),
        });
      } else {
        if (result.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado.',
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1200);

          return false;
        }

        if (result.statusCode === 200) {
          notification['success']({
            message: 'Documentos agregados exitosamente',
          });
          if (setReloadDocuments) {
            setReloadDocuments(true);
          }
          setModalIsVisible(false);
        } else {
          let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

          if (
            result.statusCode === 400 ||
            result.statusCode === 409 ||
            result.statusCode === 404
          ) {
            msg = result.description;
          }
          notification['error']({
            message: msg,
          });
        }
      }
      setLoadingImage(false);
      setLoadingForm(false);
    }
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form name="add-comment" layout="vertical" form={form}>
        <div>
          <Row xs={24}>
            <Col xs={20}>
              <Upload
                {...propsUpload}
                // accept="image/png,image/jpg"
                multiple
                listType="picture"
                fileList={filesListImages}
                defaultFileList={filesListImages}
              >
                <Button
                  style={{ width: 250, marginLeft: 0 }}
                  className="btn-download"
                  icon={<UploadOutlined />}
                >
                  Seleccionar Archivos
                </Button>
              </Upload>
            </Col>
            <Col>
              <Button
                loading={loadingImage}
                onClick={uploadExtraImageServe}
                // type="primary"
                className="btn-enviar"
                block
                style={{ marginTop: 20, marginLeft: 0 }}
              >
                Guardar archivos
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddDocumentsForm;
