import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import QuoteLinesTable from '../../../../components/Logistic/Quote/QuoteLinesTable/QuoteLinesTable';
import BackButton from '../../../../components/General/BackButton/BackButton';

export default function LinesQuote() {
  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop />
      <div className="clients__container">
        <QuoteLinesTable />
      </div>
    </Layout>
  );
}

function HeaderTop({ supplier }) {
  const { Title } = Typography;
  let { quoteId } = useParams();

  return (
    <div className="clients__container">
      <Row xs={23} className="header-top">
        <Col xs={19} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Líneas Cotización {quoteId}
          </Title>
        </Col>
        <Col xs={4}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/cotizacion'}></BackButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
