import React, { useEffect, useState } from 'react';
import {
  Table,
  notification,
  Tag,
  Button,
  Col,
  Row,
  Modal as ModalAntd,
} from 'antd';
import { getAllClients, deleteClientApi } from '../../../../api/clients';
import { getAccessTokenApi } from '../../../../api/auth';
import AddClientForm from '../ClientsTable/AddClientForm';
import EditClientForm from '../ClientsTable/EditClientForm';
import Modal from '../../../General/Modal';
import {
  ExclamationCircleOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import './ClientsTable.scss';
import useUser from '../../../../hooks/useUser';
import { ROL_ADMIN, ROL_LAYOUT, ROL_VENTAS } from '../../../../utils/constants';
import moment from 'moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';

export default function ClientsTable() {
  const [reloadClients, setReloadClients] = useState(true);
  const [clientSelected, SetClientSelected] = useState(null);
  const [clientsTable, setClientsTable] = useState([]);
  // const [loadingForm, setLoadingForm] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const { confirm } = ModalAntd;
  const { person } = useUser();

  useEffect(() => {
    if (reloadClients) {
      const token = getAccessTokenApi();

      getAllClients(token).then((response) => {
        // console.log(response, 'clientes');
        if (response) {
          if (response.statusCode === 200) {
            response.result.forEach((element, index) => {
              element['key'] = index;
            });
            setClientsTable(response.result);
          }
        } else {
          notification['error']({
            message: 'Inténtelomas tarde',
          });

          // setTimeout(() => {
          //     logout();
          //     window.location.reload();
          // }, 1500);
          // return;
        }
        setReloadClients(false);
      });
    }
  }, [reloadClients]);
  const checkClient = (id, client) => {
    if (client.active === false) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
    if (clientSelected === id) {
      SetClientSelected(null);
    } else {
      SetClientSelected(id);
    }
  };
  const columns = [
    {
      title: 'Selecciona',
      width: 100,
      dataIndex: 'id',
      key: 'company',
      render: (id, client) => (
        <Checkbox
          // disabled={!clientsTable?.find((st) => st.id === id)?.active}
          onChange={(val) => checkClient(id, client)}
          checked={clientSelected === id}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'ID cliente',
      width: 100,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Estatus',
      dataIndex: 'active',
      key: 'active',
      filters: [
        { text: 'Activo', value: true },
        { text: 'Inactivo', value: false },
      ],
      filteredValue: filteredInfo.active || null,
      onFilter: (value, record) => record.active === value,
      render: (active) => (
        <Tag color={active === true ? 'green' : 'red'}>
          {active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase()}
        </Tag>
      ),
      width: 150,
    },
    {
      title: 'RFC',
      dataIndex: 'fiscalID',
      key: 'fiscalID',
      width: 150,
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
      width: 150,
    },
    // {
    //     title: 'Ejecutivo CTA',
    //     dataIndex: 'email',
    //     key: 'email',
    //     width: 150,
    // },
  ];
  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
  };
  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar cliente');
      setContextModal(
        <AddClientForm
          setModalIsVisible={setModalIsVisible}
          setReloadClients={setReloadClients}
        ></AddClientForm>
      );
    } else if (type === 'Edit') {
      if (clientSelected !== null) {
        setModalIsVisible(true);

        setTitleModal('Editar cliente');
        setContextModal(
          <EditClientForm
            setModalIsVisible={setModalIsVisible}
            setReloadClients={setReloadClients}
            idClient={clientSelected}
          ></EditClientForm>
        );
      } else if (clientSelected === null) {
        notification['error']({
          message: 'Debes seleccionar un cliente',
        });
      }
    }
  };
  const handleDeleteClient = (id) => {
    if (clientSelected !== null) {
      confirm({
        title: 'Desactivar cliente',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas desactivar el cliente seleccionado?, no podrá reactivarse después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteClient(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar un cliente a desactivar',
      });
    }
  };
  const deleteClient = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteClientApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Usuario desactivado exitosamente.',
      });
      SetClientSelected(undefined);
    }
    setReloadClients(true);
  };
  const exportPdf = (clients, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Clientes`;
    mstrPdf = `Clientes-${moment().format('L')}.pdf`;

    let registros = [];
    clients.forEach((element) => {
      registros.push([
        element.id,
        element.name,
        element.active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        element.fiscalID,
        element.address,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Id. cliente', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      E3: { v: 'Dirección', t: 's' },
      head: [['Id. cliente', 'Nombre(s)', 'Estatus', 'RFC', 'Dirección']],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [41, 135, 230],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Clientes-${moment().format('L')}.xlsx`;
    mstrTitulo = `Clientes`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      E1: { v: fecha, t: 's' },

      A3: { v: 'Id. cliente', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      E3: { v: 'Dirección', t: 's' },
    };
    for (let i in entries) {
      const { id, name, active, fiscalID, address } = entries[i];
      ws['A' + renInicial] = { v: id, t: 's' };
      ws['B' + renInicial] = { v: name, t: 's' };
      ws['C' + renInicial] = {
        v: active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        t: 's',
      };
      ws['D' + renInicial] = { v: fiscalID !== null ? fiscalID : '', t: 's' };
      ws['E' + renInicial] = { v: address !== null ? address : '', t: 's' };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <>
      {[ROL_ADMIN, ROL_LAYOUT, ROL_VENTAS].some((r) =>
        person.roles.includes(r)
      ) && (
        <Row>
          <Col span={24}>
            <Button className="btn-download" onClick={() => showModal('Add')}>
              Agregar
            </Button>

            <Button className="btn-download" onClick={() => showModal('Edit')}>
              Editar
            </Button>
            <Button
              className="btn-delete"
              disabled={disabledButton || clientSelected === null}
              onClick={() => handleDeleteClient(clientSelected)}
            >
              Desactivar
            </Button>
          </Col>
        </Row>
      )}

      {/* <Spin spinning={loadingForm} tip="Cargando..."> */}
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={clientsTable}
        scroll={{ x: 500 }}
        loading={reloadClients}
        // pagination={{ defaultPageSize: 5 }}
      />
      {/* </Spin> */}
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(clientsTable)}
            disabled={clientsTable.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(clientsTable)}
            disabled={clientsTable.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
