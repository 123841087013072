/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Select,
  Button,
  Col,
  Modal as ModalAntd,
  Row,
  Table,
  Tag,
  Tooltip,
  Spin,
  Input,
  DatePicker,
  Checkbox,
  Space,
} from 'antd';
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  SearchOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import moment from 'moment';
import AddEditPricingForm from '../AddEditPricingForm/AddEditPricingForm';
import Modal from '../../General/Modal';
import ClientForm from '../ ClientForm/ClientForm';
import { formatMoney, isMobile } from '../../../utils/general';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_LAYOUT,
  ROL_PRICING,
} from '../../../utils/constants';
import { API_URL_RATE } from '../../../helpers/urls';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import {
  TYPE_OF_SERVICE_CATALOG_ID,
  SERVICES_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  HAZMAT_VALUE_ID,
} from '../../../helpers/constants';
import { getCatalogValueHandle } from '../../../helpers/handlerFunctionApi';
import Highlighter from 'react-highlight-words';

import './PricingTable.scss';
import useUser from '../../../hooks/useUser';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';

const PricingTable = ({
  pickRate,
  setRateSelected,
  filterRate,
  newLineForm,
  supplierSelected,
  setSupplierSelected,
  setModalIsVisibleRate,
  dateRange,
  routeSelected,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [rates, setRates] = useState([]);
  const [filterServiceType, setFilterServiceType] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [service, setService] = useState([]);
  const { person } = useUser();
  const [startDate, setStartDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const x = React.createRef();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [rateSelectedCheck, setRateSelectedCheck] = useState(null);
  // const hasSelected = selectedRowKeys.length > 0;
  const textModal = '¿Estás seguro que deseas borrar esta tarifa';
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const classButtonDownload = isMobile()
    ? 'btn-download-mobile'
    : 'btn-download';
  const classButtonDelete = isMobile() ? 'btn-delete-mobile' : 'btn-delete';
  useEffect(() => {
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setServiceType,
      setIsLoading,
      0
    );
    getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
  }, []);

  useEffect(() => {
    let filters = [];
    serviceType.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterServiceType(filters);
  }, [serviceType]);

  useEffect(() => {
    let filters = [];
    service.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterService(filters);
  }, [service]);

  useEffect(() => {
    setIsLoadingTable(true);
    let filters = '';
    // let filter2 = `startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;

    if (pickRate) {
      const { unit, typeOfLoad, service, typeOfService, hazmat } = filterRate;
      filters = `?Unit=${unit !== undefined ? unit : ''}&LoadType=${
        typeOfLoad !== undefined ? typeOfLoad : ''
      }&Service=${service !== undefined ? service : ''}&TypeOfService=${
        typeOfService !== undefined ? typeOfService : ''
      }&HazMat=${hazmat !== undefined ? hazmat : ''}&SupplierId=${
        supplierSelected?.supplierId !== undefined
          ? supplierSelected?.supplierId
          : ''
      }&RouteId=${routeSelected ? routeSelected.routeId : ''}`;
    }
    // else {
    //   filters = `?${filter2}`;
    // }

    const config = {
      method: METHOD_GET,
      url: `${API_URL_RATE}${filters}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response');
        if (response.statusCode === 200) {
          const allRates = response.result;
          allRates.map((rate) => (rate['key'] = rate.id));
          if (pickRate) {
            const completeRate = allRates.filter((rate) => rate.isCompleted);
            setRates(completeRate);
          } else {
            setRates(allRates);
          }
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, dateRange]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    if (pickRate) {
      setRateSelected(selectedRows[0]);
      // if (!supplierSelected) {
      //    console.log(selectedRows[0].supplier);
      //    setSupplierSelected(selectedRows[0].supplier);
      // }
      console.log(selectedRows[0], 'selectedRows[0] rate');
      setSupplierSelected(selectedRows[0].supplier);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: pickRate ? 'radio' : 'checkbox',
    onChange: onSelectChange,
  };

  const showModal = (type, rate) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar tarifa');
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          setReloadTable={setReloadTable}
        ></AddEditPricingForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar tarifa #${rate.rateId}`);
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          type="edit"
          rateId={rate.id}
          setReloadTable={setReloadTable}
        ></AddEditPricingForm>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`Tarifa #${rate.rateId}`);
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          type="watch"
          rateId={rate.id}
        ></AddEditPricingForm>
      );
    } else if (type === 'Other') {
      setModalIsVisible(true);
      setTitleModal('Clientes - VENTAS');
      setContextModal(
        <ClientForm
          setModalIsVisible={setModalIsVisible}
          // setReloadUsers={setReloadUsers}
          // idUser={userSelected}
        ></ClientForm>
      );
    }
  };
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            allowClear={false}
            format="DD/MM/YYYY"
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                rates
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                rates
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  rates
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : (
        ''
      ),
  });
  const checkRate = (id, rate) => {
    if (rateSelectedCheck === id) {
      setRateSelectedCheck(null);
    } else {
      setRateSelectedCheck(id);
    }
    if (pickRate) {
      setRateSelected(rate);
      // setSupplierSelected(rate.supplier);
      newLineForm.setFieldsValue({
        originAddress: rate.originAddress,
        destinationAddress: rate.destinationAddress,
        originZipCode: rate.originZipCode,
        destinationZipCode: rate.destinationZipCode,
        originPort: rate.originPortId,
        originAirport: rate.originAirportId,
        destinationPort: rate.destinationPortId,
        destinationAirport: rate.destinationAirportId,
        modalityId: rate.modalityId,
      });
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '',
      ellipsis: true,
      width: 50,
      // width: 100,
      dataIndex: 'id',
      key: 'company',
      render: (id, rate) => (
        <Checkbox
          onChange={() => checkRate(id, rate)}
          checked={rateSelectedCheck === id}
          style={{ padding: 10 }}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'Id. tarifa',
      dataIndex: 'rateId',
      key: 'rateId',
      width: 100,
      // fixed: 'left',
      ...getColumnSearchProps('operationIdStr'),
    },
    {
      title: 'Estatus',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      width: 100,
      // fixed: 'right',
      filters: [
        {
          text: 'Completada',
          value: true,
        },
        { text: 'Borrador', value: false },
      ],
      filteredValue: filteredInfo.isCompleted || null,
      onFilter: (value, record) => record.isCompleted === value,
      align: 'center',
      render: (isCompleted) => (
        <Tag
          icon={isCompleted ? <CheckCircleOutlined /> : <MinusCircleOutlined />}
          color={isCompleted ? 'green' : 'default'}
        >
          {isCompleted ? 'Completa' : 'Borrador'}
        </Tag>
      ),
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 130,
      // fixed: 'left',
      ...getColumnSearchProps('supplierName'),
    },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      //   fixed: 'left',
      filters: filterService,
      filteredValue: filteredInfo.service || null,
      onFilter: (value, record) => record.service === value,
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfService',
      key: 'typeOfService',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filterServiceType,
      filteredValue: filteredInfo.typeOfService || null,
      onFilter: (value, record) => record.typeOfService === value,
    },

    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountry',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCity',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'originZipCode',
          key: 'originZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'originPort',
          key: 'originPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'originAirport',
          key: 'originAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'originAddress',
          key: 'originAddress',
          width: 100,
          align: 'center',
        },
      ],
    },

    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinationCountry',
          key: 'destinationCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinationCity',
          key: 'destinationCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'destinationZipCode',
          key: 'destinationZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'destinationPort',
          key: 'destinationPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'destinationAirport',
          key: 'destinationAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'destinationAddress',
          key: 'destinationAddress',
          align: 'center',
          width: 100,
        },
      ],
    },

    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      align: 'center',
      render: (cost) => <span>{`$ ${formatMoney(cost)}`}</span>,
    },
    {
      title: 'Precio unitario',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (unitPrice) => <span>{`$ ${formatMoney(unitPrice)}`}</span>,
    },
    {
      title: 'Moneda',
      dataIndex: 'currencyStr',
      key: 'currencyStr',
      width: 100,
      align: 'center',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      align: 'center',
      render: (createdAt) => <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('createdAt'),
    },
    {
      title: 'Fecha inicial',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      align: 'center',
      render: (startDate) => (
        <>
          {startDate !== null
            ? moment.unix(startDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('startDate'),
    },

    {
      title: 'Fecha final',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (endDate) => <>{moment.unix(endDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('endDate'),
    },
    {
      title: 'Acciones',
      key: 'operation',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (rate) => (
        <>
          {[ROL_PRICING, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
            <>
              {/* {!rate.isCompleted && !pickRate && ( */}
              <Tooltip title="Editar">
                <Button
                  disabled={rate.isCompleted || pickRate}
                  style={{ marginLeft: 2 }}
                  type="primary"
                  shape="default"
                  icon={<EditOutlined />}
                  onClick={() => showModal('Edit', rate)}
                />
              </Tooltip>
              {/* )} */}
            </>
          )}
          {/* <Tooltip title="Eliminar">
                        <Button style={{marginLeft: 10}} type="primary" danger shape="default" icon={<DeleteOutlined /> } />
                    </Tooltip> */}
          <Tooltip title="Ver más información">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              shape="default"
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', rate)}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const deleteRate = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_RATE}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
        setSelectedRowKeys([]);
      }
    }
  };

  const showDeleteConfirm = (id, description = '') => {
    confirm({
      title: `${textModal}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRate(id);
      },
      onCancel() {},
    });
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
  };
  const exportPdf = (quotes, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Tarifas`;
    mstrPdf = `Tarifas-${moment().format('L')}.pdf`;

    var registros = [];
    quotes.forEach((element) => {
      registros.push([
        element.rateId,
        element.isCompleted ? 'Completa' : 'Borrador',
        element.supplierName,
        element.service,
        element.typeOfService,
        element.originCity,
        element.destinationCity,
        element.cost !== 0
          ? `$ ${formatMoney(element.cost)}`
          : `$ ${formatMoney(element.unitPrice)}`,
        element.currencyStr,
        moment.unix(element.createdAt).format('DD/MM/YYYY'),
        moment.unix(element.startDate).format('DD/MM/YYYY'),
        moment.unix(element.endDate).format('DD/MM/YYYY'),
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Id. tarifa', t: 's' },
      B3: { v: 'Estatus', t: 's' },
      C3: { v: 'Proveedor', t: 's' },
      D3: { v: 'Servicio', t: 's' },
      E3: { v: 'Tipo de servicio', t: 's' },
      F3: { v: 'Ciudad origen', t: 's' },
      G3: { v: 'Ciudad destino', t: 's' },
      H3: { v: 'Costo/Precio unitario', t: 's' },
      I3: { v: 'Moneda', t: 's' },
      J3: { v: 'Fecha creación', t: 's' },
      K3: { v: 'Fecha inicial', t: 's' },
      L3: { v: 'Fecha final', t: 's' },
      head: [
        [
          'Id. tarifa',
          'Estatus',
          'Proveedor',
          'Servicio',
          'Tipo de servicio',
          'Ciudad origen',
          'Ciudad destino',
          'Costo/Precio unitario',
          'Moneda',
          'Fecha creación',
          'Fecha inicial',
          'Fecha final',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [41, 135, 230],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Tarifas-${moment().format('L')}.xlsx`;
    mstrTitulo = `Tarifas`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Id. tarifa', t: 's' },
      B3: { v: 'Estatus', t: 's' },
      C3: { v: 'Proveedor', t: 's' },
      D3: { v: 'Servicio', t: 's' },
      E3: { v: 'Tipo de servicio', t: 's' },
      F3: { v: 'Ciudad origen', t: 's' },
      G3: { v: 'Ciudad destino', t: 's' },
      H3: { v: 'Costo/Precio unitario', t: 's' },
      I3: { v: 'Moneda', t: 's' },
      J3: { v: 'Fecha creación', t: 's' },
      K3: { v: 'Fecha inicial', t: 's' },
      L3: { v: 'Fecha final', t: 's' },
    };

    for (let i in entries) {
      const {
        rateId,
        isCompleted,
        supplierName,
        service,
        typeOfService,
        originCity,
        destinationCity,
        cost,
        unitPrice,
        currencyStr,
        createdAt,
        startDate,
        endDate,
      } = entries[i];
      ws['A' + renInicial] = { v: rateId, t: 's' };
      ws['B' + renInicial] = {
        v: isCompleted ? 'Completa' : 'Borrador',
        t: 's',
      };
      ws['C' + renInicial] = { v: supplierName, t: 's' };
      ws['D' + renInicial] = { v: service, t: 's' };
      ws['E' + renInicial] = { v: typeOfService, t: 's' };
      ws['F' + renInicial] = { v: originCity, t: 's' };
      ws['G' + renInicial] = { v: destinationCity, t: 's' };
      ws['H' + renInicial] = {
        v:
          cost !== 0 ? `$ ${formatMoney(cost)}` : `$ ${formatMoney(unitPrice)}`,
        t: 's',
      };
      ws['I' + renInicial] = { v: currencyStr, t: 's' };

      ws['J' + renInicial] = {
        v: moment.unix(createdAt).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['K' + renInicial] = {
        v: moment.unix(startDate).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['L' + renInicial] = {
        v: moment.unix(endDate).format('DD/MM/YYYY'),
        t: 's',
      };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <div>
      {[ROL_PRICING, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
        <>
          {!pickRate && (
            <>
              <Button
                className={classButtonDownload}
                onClick={() => showModal('Add')}
                icon={<PlusCircleOutlined />}
              >
                Agregar
              </Button>
              <Button
                disabled={!rateSelectedCheck}
                className={classButtonDelete}
                onClick={() => showDeleteConfirm(rateSelectedCheck)}
                icon={<DeleteOutlined />}
              >
                Eliminar
              </Button>
              <Button
                className={classButtonDownload}
                style={{ marginTop: 10, marginBottom: 20 }}
                icon={<ReloadOutlined />}
                onClick={() => setReloadTable(true)}
              >
                Refrescar
              </Button>
              {selectedRowKeys.length > 0 && (
                <p>Seleccionados: {selectedRowKeys.length} elementos</p>
              )}
            </>
          )}
        </>
      )}

      {pickRate && (
        <FilterRate
          filterRate={filterRate}
          supplierSelected={supplierSelected}
          routeSelected={routeSelected}
        />
      )}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}

        dataSource={rates}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={isLoadingTable || isLoading}
        onChange={handleChange}
        footer={() => {
          return (
            <FooterTable
              pickRate={pickRate}
              setModalIsVisibleRate={setModalIsVisibleRate}
              // clearSupplier={clearSupplier}
            />
          );
        }}
      />
      {!pickRate && (
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              icon={<FilePdfFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={() => exportPdf(rates)}
              disabled={rates.length === 0}
              style={{ width: 150 }}
            >
              Descargar Pdf
            </Button>
            <Button
              icon={<FileExcelFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={() => generateExcel(rates)}
              disabled={rates.length === 0}
              style={{ width: 160 }}
            >
              Descargar Excel
            </Button>
          </Col>
        </Row>
      )}
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default PricingTable;

function FilterRate({ filterRate, supplierSelected, routeSelected }) {
  const [formFilter] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
  const [hazMatCatalog, setHazMatsCatalog] = useState([]);
  const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
  const [servicesCatalog, setServicesCatalog] = useState([]);
  const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);

  useEffect(() => {
    setLoadingForm(true);

    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setUnitTypeCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(HAZMAT_CATALOG_ID, setHazMatsCatalog, setLoadingForm);
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setTypeOfServiceCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      SERVICES_CATALOG_ID,
      setServicesCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoadCatalog,
      setLoadingForm
    );
    setLoadingForm(false);
  }, []);

  useEffect(() => {
    formFilter.setFieldsValue({
      unitsArray: filterRate.unit || filterRate.equipmentType,
      typeOfLoadArray: filterRate.typeOfLoad,
      servicesArray: filterRate.service,
      typeOfServiceArray: filterRate.typeOfService,
      hazMatArray:
        filterRate.typeOfLoad === HAZMAT_VALUE_ID ? filterRate.hazMat : '',
      supplierId: supplierSelected?.supplierId,
    });
    if (routeSelected) {
      formFilter.setFieldsValue({
        routeInput: routeSelected.routeStr,
      });
    }
  }, [filterRate]);

  return (
    <Spin spinning={loadingForm}>
      <Form {...layout} form={formFilter} name="control-hooks">
        <Row>
          <Col md={12} xs={24}>
            <Form.Item label="Tipo de carga:" name="typeOfLoadArray">
              <Select
                disabled
                placeholder="Selecciona una opción"
                allowClear
                showArrow
              >
                {typeOfLoadCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Servicios:" name="servicesArray">
              <Select
                disabled
                placeholder="Selecciona una opción"
                allowClear
                showArrow
              >
                {servicesCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Tipo servicio:" name="typeOfServiceArray">
              <Select
                allowClear
                placeholder="Selecciona una opción"
                disabled
                showArrow
              >
                {typeOfServiceCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Unidades:" name="unitsArray">
              <Select placeholder="Selecciona una opción" disabled showArrow>
                {unitTypeCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="HAZ MAT:" name="hazMatArray">
              <Select
                allowClear
                placeholder="Selecciona una opción"
                disabled
                showArrow
              >
                {hazMatCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Id. proveedor:" name="supplierId">
              <Input placeholder="Ingresa tu información" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Ruta:" name="routeInput">
          <Input disabled />
        </Form.Item>
      </Form>
    </Spin>
  );
}

function FooterTable({ pickRate, setModalIsVisibleRate }) {
  return (
    <div className="footer-table">
      {pickRate && (
        <>
          {/* <Button
                  icon={<ClearOutlined />}
                  title='Limpiar proveedor'
                  style={{ marginRight: 10 }}
                  onClick={() => clearSupplier()}
               >
                  Limpiar proveedor
               </Button> */}

          <Button
            type="primary"
            onClick={() => {
              setModalIsVisibleRate(false);
            }}
          >
            Aceptar
          </Button>
        </>
      )}
    </div>
  );
}
