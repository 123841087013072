import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  notification,
  Spin,
  Input,
  Space,
  Tooltip,
  Form,
  Row,
  Col,
  Modal as ModalAntd,
  Select,
  Divider,
  Tag,
} from 'antd';
import {
  SearchOutlined,
  TeamOutlined,
  ExclamationCircleOutlined,
  ContainerOutlined,
  ClearOutlined,
  EyeOutlined,
  DiffOutlined,
  CarOutlined,
  FilterFilled,
  FilterOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router';

import { getSuppliersFinancialApi } from '../../../../api/financial';
import useUser from '../../../../hooks/useUser';
import { deleteSupplierApi, getAllSuppliers } from '../../../../api/supplier';
import {
  METHOD_GET,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_PRICING,
  ROL_VENTAS,
} from '../../../../utils/constants';
import AddSupplierForm from '../../Administration/SuppliersTable/AddSupplierForm';
import EditSupplierForm from '../../Administration/SuppliersTable/EditSupplierForm';
import Modal from '../../../General/Modal';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  CERTIFICATIONS_CATALOG_ID,
  CITY_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
  STATE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  SERVICES_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  PACKAGING_UNIT_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
  HAZMAT_VALUE_ID,
  CURRENCY_CATALOG_ID,
} from '../../../../helpers/constants';
import { fetchApi } from '../../../../helpers/fetch';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import './SuppliersTable.scss';
import useWindowDimensions from '../../../../hooks/useWindowdimensions';
import {
  formatPhoneNumber,
  isMobile,
  messageErrorGeneral,
} from '../../../../utils/general';
import useMenuCollapse from '../../../../hooks/useMenuCollapse';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import moment from 'moment';

export default function SuppliersTable(props) {
  const {
    pickSupplier,
    setSupplierSelected: setSupplierSelectedPricing,
    form: formPricing,
    setModalIsVisible: setModalIsVisiblePricing,
    filterRate,
    clientSelected,
    routeSelected,
    routeSelectedPricing,
    isRFQ,
    supplierActive,
  } = props;
  const [reloadSuppliers, setReloadSuppliers] = useState(true);
  const [usersTable, setUsersTable] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();
  const history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [supplierSelected, setSupplierSelected] = useState(null);
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { width } = useWindowDimensions();

  const [operationsCatalog, setOperationsCatalog] = useState([]);
  const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
  const [certificationsCatalog, setCertificationsCatalog] = useState([]);
  const [hazMatCatalog, setHazMatsCatalog] = useState([]);
  const [currencyCatalog, setCurrencyCatalog] = useState([]);
  const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
  const [packagingCatalog, setPackagingCatalog] = useState([]);
  const [measurementUnitCatalog, setMeasurementUnitCatalog] = useState([]);
  const [servicesCatalog, setServicesCatalog] = useState([]);
  const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);
  const [selectedrowKeys, setSelectedrowKeys] = useState([]);
  const { menuCollapsed } = useMenuCollapse();
  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledActive, setDisabledActive] = useState(true);
  const [disabledFilters, setDisabledFilters] = useState(true);
  const classButtonDownload = isMobile()
    ? 'btn-download-mobile'
    : 'btn-download';
  const classButtonDelete = isMobile() ? 'btn-delete-mobile' : 'btn-delete';

  useEffect(() => {
    if (reloadSuppliers) {
      form.resetFields();
      if (filterRate) {
        if (Object.entries(filterRate).length !== 0 || routeSelected) {
          filterSupplier(
            '1',
            filterRate.operation !== 0 && filterRate.operation !== undefined
              ? filterRate.operation
              : '',
            filterRate.certificationsArray,
            filterRate.unit || filterRate.equipmentType !== 0
              ? filterRate.unit || filterRate.equipmentType
              : '',
            filterRate.typeOfLoad,
            filterRate.service,
            filterRate.typeOfService,
            '',
            '',
            // '',
            // '', //   filterRate.measurementUnit,
            // '', //filterRate.packaging,
            filterRate.typeOfLoad === HAZMAT_VALUE_ID && filterRate.hazMat !== 0
              ? filterRate.hazMat
              : '',
            '',
            '',
            '',
            '',
            // filterRate.state,
            // filterRate.city,
            // filterRate.name,
            filterRate.currency !== 0 && filterRate.currency !== undefined
              ? filterRate.currency
              : ''

            // filterRate.supplierId
          );
          form.setFieldsValue({
            operationsArray:
              filterRate.operation !== 0 ? filterRate.operation : '',
            unitsArray:
              filterRate.unit || filterRate.equipmentType !== 0
                ? filterRate.unit || filterRate.equipmentType
                : '',
            typeOfLoadArray: filterRate.typeOfLoad,
            servicesArray: filterRate.service,
            typeOfServiceArray: filterRate.typeOfService,
            measurementUnitArray: filterRate.measurementUnit,
            packagingArray: filterRate.packaging,
            hazMatArray:
              filterRate.typeOfLoad === HAZMAT_VALUE_ID &&
              filterRate.hazMat !== 0
                ? filterRate.hazMat
                : '',
            currencyArray: filterRate.currency,
            routeInput:
              routeSelectedPricing !== 0 && routeSelectedPricing !== undefined
                ? routeSelectedPricing.routeStr
                : routeSelected
                ? routeSelected.routeStr
                : '',
          });
        } else {
          if (
            (routeSelected !== undefined && routeSelected !== null) ||
            (routeSelectedPricing !== null &&
              routeSelectedPricing !== undefined)
          ) {
            form.setFieldsValue({
              routeInput: routeSelected
                ? routeSelected.routeStr
                : routeSelectedPricing.routeStr,
            });
          }

          filterSupplier('0');
        }
      } else {
        if (
          (routeSelected !== undefined && routeSelected !== null) ||
          (routeSelectedPricing !== null && routeSelectedPricing !== undefined)
        ) {
          form.setFieldsValue({
            routeInput: routeSelected
              ? routeSelected.routeStr
              : routeSelectedPricing.routeStr,
          });
        }
        filterSupplier('0');
      }
    }
  }, [
    reloadSuppliers,
    person,
    filterRate,
    routeSelected,
    routeSelectedPricing,
    form,
  ]);
  function filterSupplier(
    type,
    operation,
    certification,
    unit,
    typeOfLoad,
    service,
    typeOfService,
    measurementUnit,
    packaging,
    hazmat,
    CYState,
    CYCity,
    name,
    supplierId,
    currency,
    originCity,
    destinationCity
  ) {
    setLoadingForm(true);
    let filters = `?active=${
      supplierActive !== undefined ? supplierActive : ''
    }`;
    if (
      type !== '0' ||
      clientSelected ||
      routeSelected ||
      routeSelectedPricing
    ) {
      filters = `?Certification=${
        certification !== undefined ? certification : ''
      }&Unit=${unit !== undefined && unit !== null ? unit : ''}&Operation=${
        operation !== undefined && operation !== null ? operation : ''
      }&LoadType=${typeOfLoad !== undefined ? typeOfLoad : ''}&Service=${
        service !== undefined ? service : ''
      }&TypeOfService=${
        typeOfService !== undefined ? typeOfService : ''
      }&MeasurementUnit=${
        measurementUnit !== undefined ? measurementUnit : ''
      }&Packaging=${packaging !== undefined ? packaging : ''}&HazMat=${
        hazmat !== undefined ? hazmat : ''
      }&CYState=${CYState !== undefined ? CYState : ''}&CYCity=${
        CYCity !== undefined ? CYCity : ''
      }&Name=${name !== undefined ? name : ''}&Currency=${
        currency !== undefined && currency !== null ? currency : ''
      }&SupplierId=${supplierId !== undefined ? supplierId : ''}&CustomerId=${
        clientSelected ? clientSelected.id : ''
      }&routeId=${
        routeSelected
          ? routeSelected.routeId
          : routeSelectedPricing &&
            routeSelectedPricing.routeId !== null &&
            routeSelectedPricing !== undefined
          ? routeSelectedPricing.routeId
          : ''
      }&active=${
        supplierActive !== undefined ? supplierActive : ''
      }&originCity=${
        originCity !== undefined ? originCity : ''
      }&destinationCity=${
        destinationCity !== undefined ? destinationCity : ''
      }`;
    }
    if (
      [ROL_ADMIN, ROL_MANAGER, ROL_PRICING, ROL_VENTAS, ROL_LAYOUT].some((r) =>
        person?.roles.includes(r)
      )
    ) {
      getAllSuppliers(token, filters).then((response) => {
        if (response === undefined) {
          notification['error']({
            message: 'Ocurrió un error, Inténtelo más tarde',
          });
          return false;
        }
        if (response.statusCode === 200) {
          response.result.forEach((element, index) => {
            element['key'] = index;
          });
          setUsersTable(response.result);
        } else {
          if (response.statusCode === 401) {
            notification['error']({
              message: 'Usuario no autorizado',
            });

            setTimeout(() => {
              logout();
              window.location.reload();
            }, 1500);
            return;
          }

          notification['error']({
            message: messageErrorGeneral(),
          });

          return;
        }
        setReloadSuppliers(false);
        setLoadingForm(false);
      });
    } else {
      getSuppliersFinancialApi(token, person.idUser, filters).then(
        (response) => {
          if (response) {
            if (response.statusCode === 401) {
              notification['error']({
                message: 'Usuario no autorizado',
              });

              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return;
            }
            if (response.statusCode === 200) {
              response.result.forEach((element, index) => {
                element['key'] = index;
              });
              setUsersTable(response.result);
            }
            setReloadSuppliers(false);
            setLoadingForm(false);
          }
        }
      );
    }
  }
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${STATE_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setStates(calogsValues);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getCatalogValueHandle(
      OPERACIONES_CATALOG_ID,
      setOperationsCatalog,
      setLoadingForm
      // form.getFieldValue('operationsArray')
    );
    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setUnitTypeCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      CERTIFICATIONS_CATALOG_ID,
      setCertificationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(HAZMAT_CATALOG_ID, setHazMatsCatalog, setLoadingForm);
    getCatalogValueHandle(
      CURRENCY_CATALOG_ID,
      setCurrencyCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setTypeOfServiceCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      PACKAGING_UNIT_CATALOG_ID,
      setPackagingCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      MEASUREMENT_UNIT_CATALOG_ID,
      setMeasurementUnitCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      SERVICES_CATALOG_ID,
      setServicesCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoadCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm);
    setLoadingForm(false);

    //   getStateCatalogHandle();
  }, [reloadSuppliers]);
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, false, value);
    form.setFieldsValue({
      CYCity: null,
    });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const checkUser = (id, supplier) => {
    if (supplier.active === false) {
      setDisabledActive(true);
    } else {
      setDisabledActive(false);
    }
    if (supplierSelected === id) {
      setSupplierSelected(null);
    } else {
      setSupplierSelected(id);
    }
  };
  const columns = [
    {
      title: 'Selecciona',
      ellipsis: true,
      width: pickSupplier ? 0 : 100,
      // width: 100,
      dataIndex: 'supplierId',
      key: 'company',
      render: (supplierId, supplier) => (
        <Checkbox
          // type="primary"                    {sectionContacts?.find((sc) => sc.sectionId === id)?.name}

          // disabled={
          //     !suppliersTable?.find(
          //         (st) => st.supplierId === supplierId
          //     )?.active
          // }
          onChange={() => checkUser(supplierId, supplier)}
          checked={supplierSelected === supplierId}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'Id. proveedor',
      ellipsis: true,
      dataIndex: 'supplierId',
      key: 'supplierId',
      ...getColumnSearchProps('supplierId'),
      render: (supplierId) => supplierId?.split('-')[0],
      width: 150,
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Estatus',
      dataIndex: 'active',
      key: 'active',
      filters: [
        { text: 'Activo', value: true },
        { text: 'Inactivo', value: false },
      ],
      filteredValue: filteredInfo.active || null,
      onFilter: (value, record) => record.active === value,
      render: (active) => (
        <Tag color={active === true ? 'green' : 'red'}>
          {active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase()}
        </Tag>
      ),
      width: 100,
    },
    {
      title: 'RFC',
      dataIndex: 'fiscalID',
      key: 'fiscalID',
      ...getColumnSearchProps('fiscalID'),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
      ellipsis: true,
      width: 150,
      render: (phoneNumber) => <span>{formatPhoneNumber(phoneNumber)}</span>,
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
      ...getColumnSearchProps('emailAddress'),
      ellipsis: true,
      width: 250,
    },
    {
      title: 'Contactos',
      key: 'contacts',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickSupplier ? 0 : 80,
      align: 'center',
      render: (supplier, supplierId) => (
        <>
          <Tooltip title="Contactos">
            <Button
              style={{ marginLeft: 2 }}
              type="primary"
              shape="default"
              icon={<TeamOutlined />}
              onClick={() =>
                history.push(`/proveedores/contactos/${supplier.supplierId}`)
              }
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Servicios',
      key: 'services',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickSupplier ? 0 : 80,
      align: 'center',
      render: (supplier, supplierId) => (
        <>
          <Tooltip title="Servicios adicionales">
            <Button
              style={{ marginLeft: 2 }}
              type="primary"
              shape="default"
              icon={<DiffOutlined />}
              onClick={() =>
                history.push(`/proveedores/servicios/${supplier.supplierId}`)
              }
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Patio',
      key: 'yard',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickSupplier ? 0 : 50,
      align: 'center',
      render: (supplier, supplierId) => (
        <>
          <Tooltip title="Patio">
            <Button
              style={{ marginLeft: 2 }}
              type="primary"
              shape="default"
              icon={<ContainerOutlined />}
              onClick={() =>
                history.push(`/proveedores/patio/${supplier.supplierId}`)
              }
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Rutas',
      key: 'rutes',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickSupplier ? 0 : 50,
      align: 'center',
      render: (supplier, supplierId) => (
        <>
          <Tooltip title="Rutas">
            <Button
              style={{
                marginLeft: 2,
                backgroundColor: supplier.hasRoutes ? '#1890ff' : '#e63241',
                borderColor: supplier.hasRoutes ? '#1890ff' : '#cf1322',
              }}
              type="primary"
              shape="default"
              icon={<CarOutlined />}
              onClick={() =>
                history.push(`/proveedores/rutas/${supplier.supplierId}`)
              }
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Ver',
      key: 'sup',
      fixed: 'right',
      width: pickSupplier ? 0 : 50,
      align: 'center',
      render: (supplier) => (
        <>
          <Tooltip title="Ver más información">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              shape="default"
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', supplier)}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //     `selectedRowKeys: ${selectedRowKeys}`,
      //     'selectedRows: ',
      //     selectedRows
      // );
      setSupplierSelectedPricing(selectedRows[0]);
      setSelectedrowKeys(selectedRowKeys);
      if (!isRFQ) {
        formPricing.setFieldsValue({
          supplier: selectedRows[0].name,
          ContactId: null,
          originCountry: null,
          originZipCode: null,
          originState: null,
          originCity: null,
          originAddress: null,
          destinationCountry: null,
          destinationZipCode: null,
          destinationState: null,
          destinationCity: null,
          destinationAddress: null,
          routeId: null,
        });
      } else {
        formPricing.setFieldsValue({
          supplier: selectedRows[0].name,
          ContactId: null,
        });
      }

      setDisabledButton(false);

      // setModalIsVisiblePricing(false);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  const showModal = (type, supplier) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar proveedor');
      setContextModal(
        <AddSupplierForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadSuppliers}
        ></AddSupplierForm>
      );
    } else if (type === 'Edit') {
      if (supplierSelected !== null) {
        setModalIsVisible(true);

        setTitleModal('Editar proveedor');
        setContextModal(
          <EditSupplierForm
            setModalIsVisible={setModalIsVisible}
            setReloadSuppliers={setReloadSuppliers}
            idSupplier={supplierSelected}
          ></EditSupplierForm>
        );
      } else if (supplierSelected === null) {
        notification['error']({
          message: 'Debes seleccionar un proveedor',
        });
      }
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`Proveedor ${supplier.name}`);
      setContextModal(
        <EditSupplierForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadSuppliers}
          idSupplier={supplier.supplierId}
          type="watch"
        ></EditSupplierForm>
      );
    }
  };
  const handleDeleteSupplier = (id) => {
    if (supplierSelected !== null) {
      confirm({
        title: 'Desactivar proveedor',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas desactivar el proveedor seleccionado?, no se podrá reactivar después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteSupplier(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar un proveedor a desactivar',
      });
    }
  };
  const deleteSupplier = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteSupplierApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Proveedor desactivado exitosamente.',
      });
      setSupplierSelected(null);
    }
    setReloadSuppliers(true);
  };
  const onFinish = async (values) => {
    // if (pickSupplier) {
    //   setSelectedrowKeys([]);
    //   // console.log(supplierSelectedPricing);
    //   setSupplierSelectedPricing(null);
    //   formPricing.setFieldsValue({
    //     supplier: null,
    //   });
    // }
    filterSupplier(
      '1',
      values.operationsArray,
      values.certificationsArray,
      values.unitsArray,
      values.typeOfLoadArray,
      values.servicesArray,
      values.typeOfServiceArray,
      values.measurementUnitArray,
      values.packagingArray,
      values.hazMatArray,
      values.CYState,
      values.CYCity !== null ? values.CYCity : '',
      values.name,
      // values.currency,
      values.supplierId,
      values.currencyArray,
      values.originCity,
      values.destinationCity
    );
  };
  const exportPdf = (suppliers, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Proveedores`;
    mstrPdf = `Proveedores-${moment().format('L')}.pdf`;

    let registros = [];
    suppliers.forEach((element) => {
      registros.push([
        element.supplierId,
        element.name,
        element.active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        element.fiscalID,
        element.phoneNumber,
        element.emailAddress,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Id. proveedor', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      E3: { v: 'Teléfono', t: 's' },
      F3: { v: 'Correo electrónico', t: 's' },
      head: [
        [
          'Id. proveedor',
          'Nombre(s)',
          'Estatus',
          'RFC',
          'Teléfono',
          'Correo electrónico',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [41, 135, 230],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Proveedores-${moment().format('L')}.xlsx`;
    mstrTitulo = `Proveedores`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      G1: { v: fecha, t: 's' },

      A3: { v: 'Id. proveedor', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      E3: { v: 'Teléfono', t: 's' },
      F3: { v: 'Correo electrónico', t: 's' },
    };
    for (let i in entries) {
      const { supplierId, name, active, fiscalID, phoneNumber, emailAddress } =
        entries[i];
      ws['A' + renInicial] = { v: supplierId, t: 's' };
      ws['B' + renInicial] = { v: name, t: 's' };
      ws['C' + renInicial] = {
        v: active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        t: 's',
      };
      ws['D' + renInicial] = { v: fiscalID !== null ? fiscalID : '', t: 's' };
      ws['E' + renInicial] = {
        v: phoneNumber !== null ? phoneNumber : '',
        t: 's',
      };
      ws['F' + renInicial] = {
        v: emailAddress !== null ? emailAddress : '',
        t: 's',
      };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <div className="supplier-table">
      <Form
        name="search-supplier"
        // layout='horizontal'
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ active: true }}
      >
        <div>
          <Row justify="end">
            <Col>
              <Tooltip
                title={disabledFilters ? 'Ver más filtros' : 'Ocultar filtros'}
              >
                <Button
                  shape="round"
                  onClick={() => setDisabledFilters(!disabledFilters)}
                >
                  {disabledFilters ? <FilterOutlined /> : <FilterFilled />}
                  Filtros
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
              md={12}
              sm={12}
              xs={24}
            >
              <Form.Item label="Operaciones:" name="operationsArray">
                <Select
                  disabled={pickSupplier}
                  placeholder="Selecciona una opción"
                  allowClear
                  showArrow
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {operationsCatalog.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
              md={12}
              sm={12}
              xs={24}
            >
              <Form.Item label="Certificaciones:" name="certificationsArray">
                <Select
                  placeholder="Selecciona una opción"
                  allowClear
                  showArrow
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {certificationsCatalog.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
              md={12}
              sm={12}
              xs={24}
            >
              <Form.Item label="Unidades:" name="unitsArray">
                <Select
                  allowClear
                  placeholder="Selecciona una opción"
                  disabled={pickSupplier}
                  showArrow
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  // style={{
                  //   minWidth: width > 576 ? 210 : '100%',
                  //   width: width > 576 ? 210 : '100%',
                  // }}
                >
                  {unitTypeCatalog.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!disabledFilters && (
              <>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Tipo de carga:" name="typeOfLoadArray">
                    <Select
                      disabled={pickSupplier}
                      placeholder="Selecciona una opción"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      // style={{
                      //   minWidth: width > 576 ? 200 : '100%',
                      //   width: width > 576 ? 200 : '100%',
                      // }}
                    >
                      {typeOfLoadCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Servicios:" name="servicesArray">
                    <Select
                      disabled={pickSupplier}
                      placeholder="Selecciona una opción"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      // style={{
                      //   minWidth: width > 576 ? 200 : '100%',
                      //   width: width > 576 ? 200 : '100%',
                      // }}
                    >
                      {servicesCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Tipo servicio:" name="typeOfServiceArray">
                    <Select
                      disabled={pickSupplier}
                      allowClear
                      placeholder="Selecciona una opción"
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      // style={{
                      //   minWidth: width > 576 ? 210 : '100%',
                      //   width: width > 576 ? 210 : '100%',
                      // }}
                    >
                      {typeOfServiceCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Patio-sede estado:" name="CYState">
                    <Select
                      // disabled={disableAllInputs}
                      placeholder="Selecciona una opción"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={onChangeState}
                      // style={{
                      //   minWidth: width > 576 ? 200 : '100%',
                      //   width: width > 576 ? 200 : '100%',
                      // }}
                    >
                      {states.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Patio-sede ciudad:" name="CYCity">
                    <Select
                      // disabled={disableAllInputs}
                      placeholder="Selecciona una opción"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }

                      // style={{
                      //   minWidth: width > 576 ? 200 : '100%',
                      //   width: width > 576 ? 200 : '100%',
                      // }}
                    >
                      {cities.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Id. proveedor:" name="supplierId">
                    <Input
                      placeholder="Ingresa tu información"
                      // style={{
                      //   minWidth: width > 576 ? 210 : '100%',
                      //   width: width > 576 ? 210 : '100%',
                      // }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="HAZ-MAT:" name="hazMatArray">
                    <Select
                      allowClear
                      placeholder="Selecciona una opción"
                      disabled={pickSupplier}
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      // style={{
                      //   minWidth: width > 576 ? 200 : '100%',
                      //   width: width > 576 ? 200 : '100%',
                      // }}
                    >
                      {hazMatCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {!pickSupplier && (
                  <Col
                    lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                    md={12}
                    sm={12}
                    xs={24}
                  >
                    <Form.Item label="Ciudad origen:" name="originCity">
                      <Select
                        allowClear
                        placeholder="Selecciona una opción"
                        disabled={pickSupplier}
                        showArrow
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {cities.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {!pickSupplier && (
                  <Col
                    lg={width < 1200 ? (!menuCollapsed ? 8 : 6) : 6}
                    md={12}
                    sm={12}
                    xs={24}
                  >
                    <Form.Item label="Ciudad destino:" name="destinationCity">
                      <Select
                        allowClear
                        placeholder="Selecciona una opción"
                        disabled={pickSupplier}
                        showArrow
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {cities.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col
                  lg={width < 1200 ? (!menuCollapsed ? 8 : 12) : 12}
                  md={24}
                  sm={12}
                  xs={24}
                >
                  <Form.Item label="Nombre:" name="name">
                    <Input placeholder="Ingresa tu información" />
                  </Form.Item>
                </Col>
                {pickSupplier && (
                  <Col
                    lg={width < 1200 ? (!menuCollapsed ? 8 : 12) : 12}
                    md={8}
                    sm={12}
                    xs={24}
                  >
                    <Form.Item label="Moneda:" name="currencyArray">
                      <Select
                        allowClear
                        placeholder="Selecciona una opción"
                        disabled={pickSupplier}
                        showArrow
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {currencyCatalog.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {pickSupplier && (
                  <Col lg={18} md={16} xs={24}>
                    <Form.Item label="Ruta:" name="routeInput">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}

            <Col
              md={24}
              xs={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{ marginBottom: 10 }}
                className="btn-enviar"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Buscar
              </Button>
              <Button
                style={{ width: 150 }}
                className="btn-download"
                onClick={(e) => setReloadSuppliers(true)}
                icon={<ClearOutlined />}
              >
                Limpiar filtros
              </Button>
            </Col>
          </Row>
        </div>
        {/* </CollapsePanel> */}
        {/* </Collapse> */}
      </Form>
      <Divider></Divider>
      <Spin spinning={loadingForm} tip="Cargando...">
        {[ROL_ADMIN, ROL_PRICING, ROL_LAYOUT].some((r) =>
          person.roles.includes(r)
        ) && (
          <>
            {!pickSupplier && (
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col span={24}>
                  <Button
                    className={classButtonDownload}
                    onClick={() => showModal('Add')}
                  >
                    Agregar
                  </Button>

                  <Button
                    className={classButtonDownload}
                    onClick={() => showModal('Edit')}
                  >
                    Editar
                  </Button>
                  <Button
                    className={classButtonDelete}
                    disabled={disabledActive}
                    onClick={() => handleDeleteSupplier(supplierSelected)}
                  >
                    Desactivar
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}

        <Table
          onChange={handleChange}
          columns={columns}
          dataSource={usersTable}
          scroll={{ x: 500 }}
          loading={reloadSuppliers}
          // bordered
          // className="table-striped-rows"
          rowSelection={
            !pickSupplier
              ? null
              : {
                  type: 'radio',
                  selectedRowKeys: selectedrowKeys,
                  ...rowSelection,
                }
          }
          footer={() => {
            return (
              <FooterTable
                pickSupplier={pickSupplier}
                setModalIsVisiblePricing={setModalIsVisiblePricing}
                disabledButton={disabledButton}
              />
            );
          }}
          // pagination={{ defaultPageSize: 5 }}
        />
        {!pickSupplier && (
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                icon={<FilePdfFilled style={{ fontSize: 18 }} />}
                className="btn-download"
                onClick={() => exportPdf(usersTable)}
                disabled={usersTable.length === 0}
                style={{ width: 150 }}
              >
                Descargar Pdf
              </Button>
              <Button
                icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                className="btn-download"
                onClick={() => generateExcel(usersTable)}
                disabled={usersTable.length === 0}
                style={{ width: 160 }}
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>
        )}

        <Modal
          title={titleModal}
          visible={modalIsVisible}
          setIsVisible={setModalIsVisible}
          width={1000}
        >
          {contextModal}
        </Modal>
      </Spin>
    </div>
  );
}

function FooterTable({
  pickSupplier,
  setModalIsVisiblePricing,
  disabledButton,
}) {
  return (
    <div className="footer-table">
      {pickSupplier && (
        <Button
          type="primary"
          disabled={disabledButton}
          onClick={() => {
            setModalIsVisiblePricing(false);
          }}
        >
          Aceptar
        </Button>
      )}
    </div>
  );
}
