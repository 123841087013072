import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Input,
  Space,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  SearchOutlined,
  FilePdfOutlined,
  FileOutlined,
  FileMarkdownOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { formatMoney } from '../../../../utils/general';
import Highlighter from 'react-highlight-words';
import FileForm from '../FileForm/FileForm';
import Modal from '../../../General/Modal';
import 'jspdf-autotable';
import BalanceForm from '../BalanceForm';

import useStatusFilter from '../../../../hooks/useStatusFilter';
import PaymentInvoice from '../PaymentInvoiceForm/PaymentInvoice';
import { getAccessTokenApi } from '../../../../api/auth';

export default function PurchaseOrderTable({ invoices, supplierName }) {
  const [purchaseOrders, setPurchaseOrders] = useState(invoices);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [loadinTable, setLoadinTable] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { statusFilter, reset } = useStatusFilter();
  const [startDate, setStartDate] = useState({
    documentDate: '',
    issueDate: '',
    programDate: '',
  });
  const [endDate, setEndDate] = useState({
    documentDate: '',
    issueDate: '',
    programDate: '',
  });
  const x = React.createRef();

  useEffect(() => {}, [invoices]);
  useEffect(() => {
    if (statusFilter.type === '') {
      setFilteredInfo({});
    }
  }, [statusFilter]);

  useEffect(() => {
    //filtar dependiendo el estatus de los amount
    const statusReceive = statusFilter.type;
    if (statusReceive !== '') {
      // console.log(statusFilter);
      // const billsFilters = purchaseOrders.filter(
      //     (bill) => bill.status === statusReceive
      // );
      let billsFilters;
      if (statusReceive === 'Total') {
        billsFilters = purchaseOrders.filter(
          (bill) =>
            bill.invoiceStatus === 'Vigente' || bill.invoiceStatus === 'Vencido'
        );
      } else {
        billsFilters = purchaseOrders.filter(
          (bill) => bill.invoiceStatus === statusReceive
        );
      }

      const filterEntrance = {
        documentType: null,
        invoiceSerieType: null,
        invoiceNum: null,
        currencyId: null,
        hasOpenNote: null,
        status:
          statusReceive === 'Total' ? ['Vigente', 'Vencido'] : [statusReceive],
      };
      const pagination = {
        current: 1,
        pageSize: 10,
      };

      const extra = {
        currentDataSource: billsFilters,
        action: 'filter',
      };

      handleChange(pagination, filterEntrance, {}, extra);
      setTimeout(() => {
        reset();
      }, 3000);
      // setFisrtTime(false);
    }
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                purchaseOrders
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                purchaseOrders
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  purchaseOrders
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text !== 0 && text !== null ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : null,
  });

  const columns = [
    {
      title: 'Id. operación',
      dataIndex: 'operationIdStr',
      key: 'operationIdStr',
      ...getColumnSearchProps('operationIdStr'),
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      render: (_) => <>{supplierName}</>,
    },
    {
      title: 'Fecha recepción',
      dataIndex: 'issueDate',
      key: 'issueDate',
      align: 'center',
      width: 120,
      //   render: (issueDate) => <>{moment.unix(issueDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('issueDate'),
    },
    {
      title: 'Fecha documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      align: 'center',
      width: 120,
      render: (documentDate) => (
        <>{moment.unix(documentDate).format('DD/MM/YYYY')}</>
      ),
      ...getColumnSearchDateProps('documentDate'),
    },
    {
      title: 'Tipo de documento',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Ingreso', value: 'I' },
        { text: 'Egreso', value: 'E' },
      ],
      filteredValue: filteredInfo.documentType || null,
      onFilter: (value, record) => record.documentType.includes(value),

      render: (documentType) =>
        `${documentType === 'I' ? 'Ingreso' : 'Egreso'}`,
      ellipsis: false,
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      key: 'serie',
      align: 'center',
      ...getColumnSearchProps('serie'),
      render: (serie) => `${serie}`,
    },
    {
      title: 'Folio',
      dataIndex: 'invoiceNum',
      align: 'center',
      key: 'invoiceNum',
      ...getColumnSearchProps('invoiceNum'),
      render: (folio, invoiceSerie) => (
        <Tag
          style={{ cursor: 'pointer' }}
          onClick={() => showModal(`Folio`, invoiceSerie)}
        >
          {folio}
        </Tag>
      ),
    },
    {
      title: 'Importe',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      render: (total) => `$${formatMoney(total)}`,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      align: 'center',
      key: 'totbalanceal',
      render: (balance) => `$${formatMoney(balance)}`,
    },
    {
      title: 'Moneda',
      dataIndex: 'currencyId',
      key: 'currencyId',
      align: 'center',
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currencyId || null,
      onFilter: (value, record) => record.currencyId.includes(value),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [
        { text: 'Vigente', value: 'VIGENTE' },
        { text: 'Pagado', value: 'PAGADO' },
        { text: 'Vencido', value: 'VENCIDO' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'VIGENTE'
              ? 'orange'
              : status === 'PAGADO'
              ? 'green'
              : 'red'
          }
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Programación de pago',
      dataIndex: 'programDate',
      key: 'programDate',
      align: 'center',
      width: 100,
      render: (programDate) => (
        <>
          {programDate !== null
            ? moment.unix(programDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('programDate'),
    },
    {
      title: 'Término de pago',
      dataIndex: 'paymentTerms',
      key: 'paymentTerms',
      align: 'center',
      width: 100,
      ...getColumnSearchProps('paymentTerms'),
    },
    {
      title: 'Archivos de facturas',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 120,
      render: (id, invoice) => (
        <>
          <Tooltip title="Xml">
            <Tag color={'green'}>
              <FileMarkdownOutlined onClick={() => showModal('xml', id)} />
            </Tag>
          </Tooltip>

          <Tooltip title="PDF">
            <Tag color={'red'}>
              <FilePdfOutlined onClick={() => showModal('pdf', id)} />
            </Tag>
          </Tooltip>
          {invoice.hasAcuse !== false && (
            <Tooltip title="cfdi">
              <Tag color={'orange'}>
                <FileOutlined onClick={() => showModal('cfdi', id)} />
              </Tag>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
  };

  const showModal = (type, reference) => {
    setModalIsVisible(true);
    if (type === 'xml') {
      setTitleModal('Archivo XML');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'pdf') {
      setTitleModal('Archivo PDF');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'cfdi') {
      setTitleModal('Archivo Acuse de recibo');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
  };

  return (
    <>
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={purchaseOrders}
        scroll={{ x: 1000 }}
        loading={loadinTable}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
