import { Button, Table, Modal as ModalAntd, Tooltip, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Modal from '../../../../../General/Modal';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { formatMoney } from '../../../../../../utils/general';
import FilterAdditionalServices from '../../../../Quote/AddQuoteForm/FilterAdditionalServices';
import AddAdditionalService from '../AddAdditionalService/AddAdditionalService';
import ImportAdditionalService from '../ImportAdditionalService/ImportAdditionalService';

const AdditionalServices = ({
  additionalServices,
  setAdditionalServices,
  type,
  quoteSelected,
  quoteId,
  lines,
  typeWatch,
  delivered,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [reloadTable, setReloadTable] = useState(false);
  const { confirm } = ModalAntd;
  const [disableInputs, setDisableInputs] = useState(
    typeWatch || type === 'watch' ? true : false
  );
  useEffect(() => {
    setReloadTable(false);
  }, []);
  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      render: (key) => <>{<p>{key + 1}</p>}</>,
    },
    {
      title: 'Servicio',
      dataIndex: 'serviceStr',
      key: 'serviceStr',
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfServiceStr',
      key: 'typeOfServiceStr',
    },
    {
      title: 'Servicio adicional',
      dataIndex: 'additionalServiceName',
      key: 'additionalServiceName',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => <p>${formatMoney(cost)}</p>,
    },
    {
      title: 'Acciones',
      key: 'acc',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (dimensionRow) => (
        <>
          <Tooltip title="Eliminar">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              shape="default"
              disabled={disableInputs || delivered}
              icon={<DeleteOutlined />}
              onClick={() => handleDeletRow(dimensionRow)}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const columnsOperation = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: 80,
      align: 'center',
      render: (key) => <>{<p>{key + 1}</p>}</>,
    },
    {
      title: 'No. línea',
      dataIndex: 'operationLineNumber',
      key: 'operationLineNumber',
      width: 80,
      align: 'center',
      render: (operationLineNumber) => <>{<p>{operationLineNumber}</p>}</>,
    },
    {
      title: 'Servicio',
      dataIndex: 'serviceStr',
      key: 'serviceStr',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfServiceStr',
      key: 'typeOfServiceStr',
      width: 80,
      align: 'center',
    },
    {
      title: 'Servicio adicional',
      dataIndex: 'additionalServiceName',
      key: 'additionalServiceName',
      width: 80,
      align: 'center',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 80,
      align: 'center',
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      width: 80,
      align: 'center',
      render: (cost) => <p>${formatMoney(cost)}</p>,
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      width: 80,
      key: 'profit',
      align: 'center',
      render: (profit) => <p>${formatMoney(profit)}</p>,
    },
    {
      title: 'Acciones',
      key: 'acc',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (dimensionRow, services) => (
        <>
          {type === 'operation' && (
            <Tooltip title="Editar">
              <Button
                style={{ marginLeft: 2 }}
                type="primary"
                shape="default"
                icon={<EditOutlined />}
                disabled={disableInputs || delivered}
                onClick={() => showModal('Services', dimensionRow, 'edit')}
              />
            </Tooltip>
          )}
          <Tooltip title="Eliminar">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              shape="default"
              disabled={disableInputs || delivered}
              icon={<DeleteOutlined />}
              onClick={() => handleDeletRow(dimensionRow)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleDeletRow = (dimensionRow) => {
    confirm({
      title: 'Eliminar servicio adicional',
      icon: <ExclamationCircleOutlined />,
      content:
        '¿Estás seguro que deseas borrar el servicio adicional seleccionado?, no podrá recuperarlo después',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let copy = [...additionalServices];
        const found = copy.findIndex((_, i) => i === dimensionRow.key);
        copy.splice(found, 1);
        setAdditionalServices(copy);
      },
      onCancel() {},
    });
  };
  const showModal = (type, asSelected, typeEdit) => {
    setModalIsVisible(true);
    if (type === 'Add') {
      setTitleModal('Agregar servicios adicioanales');
      setContextModal(
        <FilterAdditionalServices
          setModalIsVisible={setModalIsVisible}
          showModal={showModal}
        />
      );
    } else if (type === 'Services') {
      setTitleModal(
        typeEdit === 'edit'
          ? 'Editar servicio adicional'
          : 'Agregar servicio adicional'
      );
      setContextModal(
        <AddAdditionalService
          setModalIsVisible={setModalIsVisible}
          asSelected={asSelected}
          additionalServices={additionalServices}
          setAdditionalServices={setAdditionalServices}
          type={typeEdit}
          lines={lines}
          // lineId={lineSelected}
        />
      );
      setModalIsVisible(true);
    } else if (type === 'Import') {
      setTitleModal('Importar servicio adicional');
      setContextModal(
        <ImportAdditionalService
          setModalIsVisible={setModalIsVisible}
          quoteSelected={quoteSelected}
          quoteId={quoteId}
          additionalServices={additionalServices}
          setAdditionalServices={setAdditionalServices}
        />
      );
      setModalIsVisible(true);
    }
  };
  return (
    <div>
      {type === 'operation' && (
        <Row gutter={16} justify="end">
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => showModal('Import')}
              className="btn-download"
              icon={<ImportOutlined />}
              style={{
                marginBottom: 16,
              }}
              disabled={disableInputs || delivered}
            >
              Importar
            </Button>
          </Col>{' '}
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* {type !== 'rfq' && ( */}
            <Button
              onClick={() => showModal('Add')}
              type="primary"
              icon={<PlusCircleOutlined />}
              style={{
                marginBottom: 16,
                borderRadius: 4,
              }}
              disabled={disableInputs || delivered}
            >
              Nuevo
            </Button>
            {/* )} */}
          </Col>
        </Row>
      )}
      <Table
        // columns={additionalServices[0]?.lineId ? columnsOperation : columns}
        columns={type === 'operation' ? columnsOperation : columns}
        dataSource={additionalServices?.map((p, i) => ({ ...p, key: i }))}
        bordered
        size="middle"
        scroll={{ x: 500 }}
        setSelectedRowKeys
        className="table-striped-rows"
        loading={reloadTable}
        // loading={reload}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default AdditionalServices;
