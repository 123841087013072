import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Input,
  Space,
  Tooltip,
  DatePicker,
} from 'antd';
import moment from 'moment';
import Modal from '../../General/Modal';
import {
  FileExcelFilled,
  FilePdfFilled,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  ROL_ADMIN,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_OPERACIONES,
  ROL_VENTAS,
} from '../../../utils/constants';

// import './BillingTable.scss';
import AddEditInvoiceOperation from '../../Logistic/Operations/AddEditInvoiceOperation';
import useUser from '../../../hooks/useUser';
import { formatMoney } from '../../../utils/general';
import BalanceForm from '../Billling/BalanceForm';
import FileForm from '../Billling/FileForm/FileForm';
export default function AccountBalance({
  balance,
  loadingForm,
  setTotal,
  dateRange,
  total,
  titleFilter,
}) {
  const [billsData, setBillsData] = useState(balance);
  const [billsExport, setbillsExport] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [startDate, setStartDate] = useState({
    date: '',
    dueDate: '',
  });
  const [endDate, setEndDate] = useState({
    date: '',
    dueDate: '',
  });
  const x = React.createRef();

  useEffect(() => {
    setBillsData(balance);
    setbillsExport(balance);
  }, [balance]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                billsData
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                billsData
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  billsData
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text !== 0 && text !== null ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : null,
  });
  const columns = [
    {
      title: 'No. folio',
      dataIndex: 'folio',
      key: 'folio',
      render: (folio) => <p>{folio}</p>,
      ...getColumnSearchProps('folio'),
      width: 200,
    },
    {
      title: 'No. factura',
      dataIndex: 'number',
      key: 'number',
      ...getColumnSearchProps('number'),
      width: 100,
    },
    {
      title: 'Fecha emisión',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      ...getColumnSearchDateProps('date'),
      width: 150,
    },
    {
      title: 'Fecha vencimiento',
      dataIndex: 'dueDate',
      key: 'dueDate',
      ellipsis: true,
      ...getColumnSearchDateProps('dueDate'),
      width: 150,
    },
    {
      title: 'Referencia operación',
      dataIndex: 'operationIdStr',
      key: 'operationIdStr',
      ...getColumnSearchProps('operationIdStr'),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Vigente', value: 'Vigente' },
        { text: 'Pagado', value: 'Pagado' },
        { text: 'Vencido', value: 'Vencido' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'Vigente'
              ? 'green'
              : status === 'Pagado'
              ? 'orange'
              : 'red'
          }
        >
          {status.toUpperCase()}
        </Tag>
      ),
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currency || null,
      onFilter: (value, record) => record.currency.includes(value),
      width: 120,
    },
    {
      title: 'Subtotal',
      dataIndex: 'subTotal',
      key: 'subTotal',
      ...getColumnSearchProps('subTotal'),
      render: (total) => `$ ${formatMoney(total)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      render: (total) => `$ ${formatMoney(total)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      ...getColumnSearchProps('balance'),
      render: (balance) => `$ ${formatMoney(balance)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Impuestos',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      ...getColumnSearchProps('taxAmount'),
      render: (taxAmount) => `$ ${formatMoney(taxAmount)}`,
      ellipsis: true,
      width: 120,
    },

    // {
    //   title: 'Archivos de facturas',
    //   dataIndex: 'id',
    //   key: 'id',
    //   align: 'center',
    //   width: 200,
    //   render: (id, invoice) => (
    //     <>
    //       <Tooltip title="Xml">
    //         <Tag color={'green'}>
    //           <FileMarkdownOutlined onClick={() => showModalFiles('xml', id)} />
    //         </Tag>
    //       </Tooltip>

    //       <Tooltip title="PDF">
    //         <Tag color={'red'}>
    //           <FilePdfOutlined onClick={() => showModalFiles('pdf', id)} />
    //         </Tag>
    //       </Tooltip>
    //       {invoice.hasAcuse !== false && (
    //         <Tooltip title="Archivo adicional">
    //           <Tag color={'orange'}>
    //             <FileOutlined onClick={() => showModalFiles('cfdi', id)} />
    //           </Tag>
    //         </Tooltip>
    //       )}
    //     </>
    //   ),
    //   ellipsis: true,
    // },
    // {
    //   title: 'Aclaraciones',
    //   key: 'hasOpenNote',
    //   dataIndex: 'hasOpenNote',
    //   align: 'center',
    //   width: 150,
    //   ellipsis: true,
    //   filters: [
    //     { text: 'Nuevo', value: 'new' },
    //     { text: 'Abierto', value: 'open' },
    //     { text: 'Cerrado', value: 'close' },
    //   ],
    //   filteredValue: filteredInfo.hasOpenNote || null,
    //   onFilter: (value, record) => {
    //     //cerrada : hasOpenNote === false && noteid !== null
    //     if (value === 'new') {
    //       return (
    //         (!record.hasOpenNote && record.noteId === 0) ||
    //         (!record.hasOpenNote && record.noteId !== 0)
    //       );
    //     }
    //     if (value === 'open') {
    //       return record.hasOpenNote && record.noteId !== 0;
    //     }
    //     if (value === 'close') {
    //       return !record.hasOpenNote && record.noteId !== 0;
    //     }
    //   },
    //   render: (hasOpenNote, invoice) => (
    //     <>
    //       {invoice.noteId !== 0 && (
    //         <Tooltip
    //           placement="topLeft"
    //           title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
    //         >
    //           <Tag
    //             onClick={() =>
    //               history.push(`/aclaraciones/detail/${invoice.noteId}`)
    //             }
    //             color={hasOpenNote ? 'green' : 'gray'}
    //             className="pointer"
    //           >
    //             {invoice.noteId}
    //           </Tag>
    //         </Tooltip>
    //       )}
    //       {[
    //         ROL_CUSTOMER,
    //       ].some((r) => person.roles.includes(r)) && (
    //         <>
    //           {!hasOpenNote && (
    //             <Tooltip placement="topLeft" title={'Nueva'}>
    //               <Tag
    //                 onClick={() =>
    //                   history.push(`/aclaraciones/nueva/${invoice.invoiceRef}`)
    //                 }
    //                 color={'green'}
    //                 className="pointer"
    //               >
    //                 <PlusCircleOutlined />
    //               </Tag>
    //             </Tooltip>
    //           )}
    //         </>
    //       )}
    //     </>
    //   ),
    // },
  ];
  const columnsAll = [
    {
      title: 'Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
      render: (customerName) => <p>{customerName}</p>,
      ...getColumnSearchProps('customerName'),
      width: 190,
    },
    {
      title: 'No. folio',
      dataIndex: 'folio',
      key: 'folio',
      render: (folio) => <p>{folio}</p>,
      ...getColumnSearchProps('folio'),
      width: 200,
    },
    {
      title: 'No. factura',
      dataIndex: 'number',
      key: 'number',
      ...getColumnSearchProps('number'),
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Fecha emisión',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      ...getColumnSearchDateProps('date'),
      width: 150,
    },
    {
      title: 'Fecha vencimiento',
      dataIndex: 'dueDate',
      key: 'dueDate',
      ellipsis: true,
      ...getColumnSearchDateProps('dueDate'),
      width: 150,
    },
    {
      title: 'Referencia operación',
      dataIndex: 'operationIdStr',
      key: 'operationIdStr',
      ...getColumnSearchProps('operationIdStr'),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Vigente', value: 'Vigente' },
        { text: 'Pagado', value: 'Pagado' },
        { text: 'Vencido', value: 'Vencido' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'Vigente'
              ? 'green'
              : status === 'Pagado'
              ? 'orange'
              : 'red'
          }
        >
          {status.toUpperCase()}
        </Tag>
      ),
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currency || null,
      onFilter: (value, record) => record.currency.includes(value),
      width: 120,
    },
    {
      title: 'Subtotal',
      dataIndex: 'subTotal',
      key: 'subTotal',
      ...getColumnSearchProps('subTotal'),
      render: (total) => `$ ${formatMoney(total)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      render: (total) => `$ ${formatMoney(total)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      ...getColumnSearchProps('balance'),
      render: (balance) => `$ ${formatMoney(balance)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Impuestos',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      ...getColumnSearchProps('taxAmount'),
      render: (taxAmount) => `$ ${formatMoney(taxAmount)}`,
      ellipsis: true,
      width: 120,
    },

    // {
    //   title: 'Archivos de facturas',
    //   dataIndex: 'id',
    //   key: 'id',
    //   align: 'center',
    //   width: 200,
    //   render: (id, invoice) => (
    //     <>
    //       <Tooltip title="Xml">
    //         <Tag color={'green'}>
    //           <FileMarkdownOutlined onClick={() => showModalFiles('xml', id)} />
    //         </Tag>
    //       </Tooltip>

    //       <Tooltip title="PDF">
    //         <Tag color={'red'}>
    //           <FilePdfOutlined onClick={() => showModalFiles('pdf', id)} />
    //         </Tag>
    //       </Tooltip>
    //       {invoice.hasAcuse !== false && (
    //         <Tooltip title="Archivo adicional">
    //           <Tag color={'orange'}>
    //             <FileOutlined onClick={() => showModalFiles('cfdi', id)} />
    //           </Tag>
    //         </Tooltip>
    //       )}
    //     </>
    //   ),
    //   ellipsis: true,
    // },
    // {
    //   title: 'Aclaraciones',
    //   key: 'hasOpenNote',
    //   dataIndex: 'hasOpenNote',
    //   align: 'center',
    //   width: 150,
    //   ellipsis: true,
    //   filters: [
    //     { text: 'Nuevo', value: 'new' },
    //     { text: 'Abierto', value: 'open' },
    //     { text: 'Cerrado', value: 'close' },
    //   ],
    //   filteredValue: filteredInfo.hasOpenNote || null,
    //   onFilter: (value, record) => {
    //     //cerrada : hasOpenNote === false && noteid !== null
    //     if (value === 'new') {
    //       return (
    //         (!record.hasOpenNote && record.noteId === 0) ||
    //         (!record.hasOpenNote && record.noteId !== 0)
    //       );
    //     }
    //     if (value === 'open') {
    //       return record.hasOpenNote && record.noteId !== 0;
    //     }
    //     if (value === 'close') {
    //       return !record.hasOpenNote && record.noteId !== 0;
    //     }
    //   },
    //   render: (hasOpenNote, invoice) => (
    //     <>
    //       {invoice.noteId !== 0 && (
    //         <Tooltip
    //           placement="topLeft"
    //           title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
    //         >
    //           <Tag
    //             onClick={() =>
    //               history.push(`/aclaraciones/detail/${invoice.noteId}`)
    //             }
    //             color={hasOpenNote ? 'green' : 'gray'}
    //             className="pointer"
    //           >
    //             {invoice.noteId}
    //           </Tag>
    //         </Tooltip>
    //       )}
    //       {[
    //         // ROL_LAYOUT,
    //         // ROL_MANAGER,
    //         // ROL_FINANCIAL,
    //         // ROL_OPERACIONES,
    //         ROL_CUSTOMER,
    //       ].some((r) => person.roles.includes(r)) && (
    //         <>
    //           {!hasOpenNote && (
    //             <Tooltip placement="topLeft" title={'Nueva'}>
    //               <Tag
    //                 onClick={() =>
    //                   history.push(`/aclaraciones/nueva/${invoice.invoiceRef}`)
    //                 }
    //                 color={'green'}
    //                 className="pointer"
    //               >
    //                 <PlusCircleOutlined />
    //               </Tag>
    //             </Tooltip>
    //           )}
    //         </>
    //       )}
    //     </>
    //   ),
    // },
  ];
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  useEffect(() => {
    [
      ROL_MANAGER,
      ROL_FINANCIAL,
      ROL_OPERACIONES,
      ROL_LAYOUT,
      ROL_ADMIN,
      ROL_VENTAS,
    ].some((r) => person.roles.includes(r)) && setShowAllColumns(true);
    // setColumns([newColumn,...columns])
  }, [person.roles]);
  const showModal = (type) => {
    if (type === 'Saldo') {
      setTitleModal('Saldo');
    } else if (type === 'Folio') {
      setTitleModal('Folio');
    }
    setModalIsVisible(true);
    setContextModal(
      <BalanceForm
        setModalIsVisible={setModalIsVisible}
        type={type}
      ></BalanceForm>
    );
  };
  const showModalFiles = (type, reference, invoice) => {
    setModalIsVisible(true);
    if (type === 'xml') {
      setTitleModal('Archivo XML');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'pdf') {
      setTitleModal('Archivo PDF');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'cfdi') {
      setTitleModal('Archivo Acuse de recibo');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    } else if (type === 'Invoice') {
      setModalIsVisible(true);
      setTitleModal(`Facturación operación #${invoice.operationIdStr}`);
      setContextModal(
        <AddEditInvoiceOperation
          setModalIsVisible={setModalIsVisible}
          invoiceId={invoice.id}
          operationStatus={invoice.status}
          type={'watch'}
        ></AddEditInvoiceOperation>
      );
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setbillsExport(extra.currentDataSource);
  };
  const clearAll = () => {
    setFilteredInfo({});
    // setSortedInfo({});
  };
  const generateExcel = (entries) => {
    const filename = `Estado de cuenta_${moment().format('L')}.xlsx`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    var mstrTitulo = `Estado de cuenta`;
    // var mstrTitulo = `Facturación por fecha de ${titleFilter} del ${dateRange.startDate} al: ${dateRange.endDate}`;

    if (
      [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
        person.roles.includes(r)
      )
    ) {
      ws = {
        A1: { v: mstrTitulo, t: 's' },
        G1: { v: fecha, t: 's' },

        A3: { v: 'Cliente', t: 's' },
        B3: { v: 'Folio', t: 's' },
        C3: { v: 'No. factura', t: 's' },
        D3: { v: 'Emisión', t: 's' },
        E3: { v: 'Vencimiento', t: 's' },
        F3: { v: 'Referencia operación', t: 's' },
        G3: { v: 'Estatus', t: 's' },
        H3: { v: 'Moneda', t: 's' },
        I3: { v: 'Subtotal', t: 's' },
        J3: { v: 'Total', t: 's' },
        K3: { v: 'Saldo', t: 's' },
        L3: { v: 'Impuesto', t: 's' },
      };

      for (let i in entries) {
        const {
          customerName,
          folio,
          number,
          date,
          dueDate,
          operationIdStr,
          status,
          currency,
          subTotal,
          total,
          balance,
          taxAmount,
        } = entries[i];
        ws['A' + renInicial] = {
          v: customerName ? customerName : '',
          t: 's',
        };
        ws['B' + renInicial] = { v: folio ? folio : '', t: 's' };
        ws['C' + renInicial] = { v: number, t: 's' };
        ws['D' + renInicial] = {
          v: moment.unix(date).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['E' + renInicial] = {
          v: dueDate ? moment.unix(dueDate).format('DD/MM/YYYY') : '',
          t: 's',
        };
        ws['F' + renInicial] = { v: operationIdStr, t: 's' };
        ws['G' + renInicial] = { v: status, t: 's' };
        ws['H' + renInicial] = { v: currency ? currency : '', t: 's' };
        ws['I' + renInicial] = { v: `$ ${formatMoney(subTotal)}`, t: 's' };
        ws['J' + renInicial] = { v: `$ ${formatMoney(total)}`, t: 's' };
        ws['K' + renInicial] = { v: `$ ${formatMoney(balance)}`, t: 's' };
        ws['L' + renInicial] = { v: `$ ${formatMoney(taxAmount)}`, t: 's' };
        renInicial++;
      }
    } else {
      ws = {
        A1: { v: mstrTitulo, t: 's' },
        G1: { v: fecha, t: 's' },

        A3: { v: 'Folio', t: 's' },
        B3: { v: 'No. factura', t: 's' },
        C3: { v: 'Emisión', t: 's' },
        D3: { v: 'Vencimiento', t: 's' },
        E3: { v: 'Referencia operación', t: 's' },
        F3: { v: 'status', t: 's' },
        G3: { v: 'Moneda', t: 's' },
        H3: { v: 'Subtotal', t: 's' },
        I3: { v: 'Total', t: 's' },
        J3: { v: 'Saldo', t: 's' },
        K3: { v: 'Impuestos', t: 's' },
      };

      for (let i in entries) {
        const {
          folio,
          number,
          date,
          dueDate,
          operationIdStr,
          status,
          currency,
          subTotal,
          total,
          balance,
          taxAmount,
        } = entries[i];
        ws['A' + renInicial] = { v: folio ? folio : '', t: 's' };
        ws['B' + renInicial] = { v: number, t: 's' };
        ws['C' + renInicial] = {
          v: moment.unix(date).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['D' + renInicial] = {
          v: dueDate ? moment.unix(dueDate).format('DD/MM/YYYY') : '',
          t: 's',
        };
        ws['E' + renInicial] = { v: operationIdStr, t: 's' };
        ws['F' + renInicial] = { v: status, t: 's' };
        ws['G' + renInicial] = { v: currency ? currency : '', t: 's' };
        ws['H' + renInicial] = { v: `$ ${formatMoney(subTotal)}`, t: 's' };
        ws['I' + renInicial] = { v: `$ ${formatMoney(total)}`, t: 's' };
        ws['J' + renInicial] = { v: `$ ${formatMoney(balance)}`, t: 's' };
        ws['K' + renInicial] = { v: `$ ${formatMoney(taxAmount)}`, t: 's' };
        renInicial++;
      }
    }

    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
  };
  const exportPdf = (bills, startDate, endDate) => {
    var mstrTitulo = `Estado de cuenta`;
    // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
    //     .unix(startDate)
    //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
    var mstrPdf = `Estado de cuenta ${moment().format('L')}.pdf`;

    var registros = [];
    if (
      [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
        person.roles.includes(r)
      )
    ) {
      bills.forEach((element) => {
        registros.push([
          element.customerName,
          element.folio,
          element.number,
          moment.unix(element.date).format('DD/MM/YYYY'),
          element.dueDate
            ? moment.unix(element.dueDate).format('DD/MM/YYYY')
            : '',
          element.operationIdStr,
          element.status,
          element.currency,
          '$ ' + formatMoney(element.subTotal),
          '$ ' + formatMoney(element.total),
          '$ ' + formatMoney(element.balance),
          '$ ' + formatMoney(element.taxAmount),
        ]);
      });
    } else {
      bills.forEach((element) => {
        registros.push([
          element.folio,
          element.number,
          moment.unix(element.date).format('DD/MM/YYYY'),
          element.dueDate
            ? moment.unix(element.dueDate).format('DD/MM/YYYY')
            : '',
          element.operationIdStr,
          element.status,
          element.currency,
          '$ ' + formatMoney(element.subTotal),
          '$ ' + formatMoney(element.total),
          '$ ' + formatMoney(element.balance),
          '$ ' + formatMoney(element.taxAmount),
        ]);
      });
    }

    let fecha = moment().format('LLL');

    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    if (
      [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
        person.roles.includes(r)
      )
    ) {
      doc.autoTable({
        head: [
          [
            'Cliente',
            'Folio',
            'No. factura',
            'Emisión',
            'Vencimineto',
            'Referencia operación',
            'Estatus',
            'Moneda',
            'Subtotal',
            'Total',
            'Saldo',
            'Impuesto',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [41, 135, 230],
          textColor: [255, 255, 255],
        },
        body: registros,
        columnStyles: {
          0: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          1: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          5: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          text: {
            cellWidth: 'wrap',
          },
        },
      });
    } else {
      doc.autoTable({
        head: [
          [
            'Folio',
            'No. factura',
            'Emisión',
            'Vencimineto',
            'Referencia operación',
            'Estatus',
            'Moneda',
            'Subtotal',
            'Total',
            'Saldo',
            'Impuesto',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [41, 135, 230],
          textColor: [255, 255, 255],
        },
        body: registros,
        columnStyles: {
          0: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          1: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          5: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          text: {
            cellWidth: 'wrap',
          },
        },
      });
    }

    // call footer() after each doc.addPage()

    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };

  return (
    <>
      <Table
        onChange={handleChange}
        columns={showAllColumns ? columnsAll : columns}
        dataSource={billsData}
        scroll={{ x: 1200 }}
        // pagination={{
        //     pageSize: 20,
        // }}
        loading={loadingForm}
      />

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(billsExport)}
            disabled={billsExport.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(billsExport)}
            disabled={billsExport.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
