import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  message,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { METHOD_GET, METHOD_POST } from '../../../../../utils/constants';
import {
  API_URL_CATALOG_VALUES,
  API_URL_SUPPLIER_CONTACTS,
} from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import { ROLES_CONTACTO_CATALOG_ID } from '../../../../../helpers/constants';

const AddSupllierContact = ({
  setModalIsVisible,
  setReloadContacts,
  supplierId,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();
  const [serviceCatalog, setServiceCatalog] = useState([]);
  const [contacts, setContacts] = useState([]);
  const { Option } = Select;
  const [valueSelect, setValueSelect] = useState(undefined);
  const [disableInputs, setDisableInputs] = useState(true);

  useEffect(() => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${ROLES_CONTACTO_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setServiceCatalog(calogsValues);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: `Contact/GetContactsNotLinkedToSupplierOrCustomer?supplierId=${supplierId}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let contactsArray = response.result;
          if (contactsArray) {
            contactsArray.map((e, index) => {
              e['key'] = index;
              e['label'] = e.name + ' ' + e.lastName;
              e['value'] = e.id;
            });
          }
          contactsArray.sort((a, b) => a.label.localeCompare(b.label));
          setContacts(contactsArray);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    values['supplierId'] = supplierId;
    values['rolesArray'] = [values.roles];

    const config = {
      method: METHOD_POST,
      url: `${API_URL_SUPPLIER_CONTACTS}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);
    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadContacts(true);
      }
    }

    setLoading(false);
  };
  function onChangeSelectContact(value) {
    setValueSelect(value);

    if (value === 'notFound') {
      setDisableInputs(false);
      form.resetFields();
    } else {
      if (value === undefined) {
        return;
      }
      setDisableInputs(true);
      FillInfoUser(value);
    }
  }
  const onClearUserInfo = () => {
    form.resetFields();
  };
  const FillInfoUser = async (value) => {
    let found = contacts.find((obj) => obj.id === value);
    form.setFieldsValue({
      name: found.name,
      lastName: found.lastName,
      mothersLastName: found.mothersLastName,
      phone: found.phone,
      cellphone1: found.cellPhone1,
      cellphone2: found.cellPhone2,
      email: found.email,
      roles: found.roles,
      id: found.id,
    });
    message.success('Datos completados correctamente');
  };
  return (
    <Spin spinning={loading} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ active: true }}
      >
        <div>
          <Row xs={24} className="table-company">
            {contacts.length >= 0 && (
              <Col xs={20} md={20}>
                <h3>Buscar contacto o nuevo registro</h3>
                <Select
                  allowClear
                  style={{ width: '100%', marginBottom: 20 }}
                  placeholder="Busque a un usuario"
                  onChange={onChangeSelectContact}
                  value={valueSelect}
                  onClear={onClearUserInfo}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  <Option value="notFound" style={{ fontWeight: 'bold' }}>
                    Registrar uno nuevo
                  </Option>
                  {contacts.map((user) => {
                    return (
                      <Option key={user.id} value={user.id}>
                        {user.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            )}
            <Form.Item name="id" hidden></Form.Item>

            <Col xs={20}>
              <Form.Item
                label="Nombre:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese nombre',
                  },
                ]}
              >
                <Input
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item
                label="Apellido paterno:"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese apellido paterno',
                  },
                ]}
              >
                <Input
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item label="Apellido materno:" name="mothersLastName">
                <Input
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Teléfono casa/oficina:&nbsp;
                    <Tooltip title="El número telefónico debe contener 10 dígitos">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="phone"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || value.toString().length === 10) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'Por favor ingresa número telefónico a 10 dígitos'
                      );
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Celular 1:&nbsp;
                    <Tooltip title="El número telefónico debe contener 10 dígitos">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="cellphone1"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa número telefónico',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || value.toString().length === 10) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'Por favor ingresa número telefónico a 10 dígitos'
                      );
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Celular 2:&nbsp;
                    <Tooltip title="El número telefónico debe contener 10 dígitos">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="cellphone2"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || value.toString().length === 10) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'Por favor ingresa número telefónico a 10 dígitos'
                      );
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item label="Correo electrónico:" name="email">
                <Input
                  type={'email'}
                  placeholder="Ingresa tu información"
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item
                label="Rol:"
                name="roles"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese rol',
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona una opción"
                  allowClear
                  disabled={disableInputs}
                  // onChange={(value) => setService(value)}
                >
                  {serviceCatalog.map((s) => (
                    <Select.Option key={s.value} value={s.value.toString()}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Row justify="end">
                      <Button htmlType="submit" className="btn-enviar" block>
                        Guardar
                      </Button>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddSupllierContact;
