import { Button, Col, Form, Row, Select, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Modal from '../../../../General/Modal';
import { METHOD_GET } from '../../../../../utils/constants';
import { fetchApi } from '../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../api/auth';
import { API_URL_ADDITIONAL_SERVICE } from '../../../../../helpers/urls';
import { formatMoney } from '../../../../../utils/general';

const FilterAdditionalServices = ({
  setModalIsVisible: setModalFilterIsVisible,
  showModal,
}) => {
  const [modalServiceIsVisible, setModalServiceIsVisible] = useState(false);
  const [titleModalService, setTitleModalService] = useState(false);
  const [contextModalService, setContextModalService] = useState(null);
  const [searchForm] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const token = getAccessTokenApi();
  const [additionalServices, setAdditionalServices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [additionalServiceCatalog, setAdditionalServiceCatalog] = useState([]);
  const [additionalServiceSelected, setAdditionalServiceSelected] = useState(
    []
  );
  const [supplierSelected, setSupplierSelected] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [reloadTable, setReloadTable] = useState(true);
  const [filterAs, setFilterAs] = useState(false);
  const [asSelected, setasSelected] = useState({});
  const [disableButton, setDisabledButton] = useState(true);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setasSelected(selectedRows[0]);
    if (selectedRowKeys.length !== 0 && selectedRows.length !== 0) {
      setDisabledButton(false);
    }
  };
  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `${API_URL_ADDITIONAL_SERVICE}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allServices = response.result;
          allServices?.map((as) => (as['key'] = as.id));
          setAdditionalServiceCatalog(allServices);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `supplier`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allSuppliers = response.result;
          allSuppliers?.map((as, index) => (as['key'] = index));
          setSuppliers(allSuppliers);
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Servicio adicional',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => <p>${formatMoney(cost)}</p>,
    },
  ];

  const searchServiceFunction = async () => {
    setSupplierSelected([]);
    setDisabledButton(true);
    onSelectChange([], []);
    if (
      (!supplierSelected && !additionalServiceSelected) ||
      (supplierSelected === undefined && additionalServiceSelected.length === 0)
    ) {
      notification['error']({
        message: 'Debes seleccionar una opción para realizar la búsqueda',
      });
      return;
    }
    let filters = `?SupplierId=${
      supplierSelected !== undefined ? supplierSelected : ''
    }&AdditionalServiceId=${
      additionalServiceSelected !== undefined ? additionalServiceSelected : ''
    }`;
    const config = {
      method: METHOD_GET,
      url: `${API_URL_ADDITIONAL_SERVICE}/GetByFilters${filters}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response');
        if (response.statusCode === 200) {
          const allServicesTable = response.result;
          allServicesTable?.map((as) => (as['key'] = as.id));
          setAdditionalServices(allServicesTable);
          if (response.result.length === 0) {
            setDisabledButton(true);
            setSupplierSelected([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeSelect = (value, type) => {
    setFilterAs({
      ...filterAs,
      [type]: value,
    });
  };

  return (
    <div>
      <Form
        name="search-service"
        layout="vertical"
        form={searchForm}
        // onFinish={searchServiceFunction}
        //   onFinishFailed={validationQuoteField}
      >
        <Row gutter={16}>
          <Col md={8} xs={24}>
            <Form.Item label="Servicio adicional:" name="additionalService">
              <Select
                placeholder="Seleccione"
                allowClear
                // onChange={(value) => setTypeOfload(value)}
                onChange={(value) => {
                  setAdditionalServiceSelected(value);
                  onChangeSelect(value, 'additionalService');
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {additionalServiceCatalog?.map((s) => (
                  <Select.Option key={s.key} value={s.key}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item label="Proveedor:" name="supplier">
              <Select
                placeholder="Seleccione"
                allowClear
                // onChange={(value) => setTypeOfload(value)}
                onChange={(value) => {
                  setSupplierSelected(value);
                  onChangeSelect(value, 'supplier');
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {suppliers?.map((s) => (
                  <Select.Option key={s.key} value={s.supplierId}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            md={8}
            xs={24}
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <Button
              style={{ marginLeft: 0, width: 150 }}
              className="btn-download"
              block
              size="small"
              icon={<SearchOutlined />}
              onClick={() => searchServiceFunction()}
              // disabled={rateApi?.isCompleted}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={additionalServices}
        bordered
        size="middle"
        scroll={{ x: 500 }}
        className="table-striped-rows"
        setSelectedRowKeys
        rowSelection={rowSelection}
        footer={() => {
          return (
            <FooterTable
              showModal={showModal}
              asSelected={asSelected}
              disableButton={disableButton}
            />
          );
        }}
        loading={reloadTable}
      />
      <Modal
        title={titleModalService}
        visible={modalServiceIsVisible}
        setIsVisible={setModalServiceIsVisible}
        width={1000}
      >
        {contextModalService}
      </Modal>
    </div>
  );
};

export default FilterAdditionalServices;

function FooterTable({ showModal, asSelected, disableButton }) {
  return (
    <div className="footer-table">
      <>
        <Button
          type="primary"
          onClick={() => showModal('Services', asSelected, 'add')}
          disabled={disableButton}
        >
          Aceptar
        </Button>
      </>
    </div>
  );
}
