import { basePath } from '../api/config';
import { feedbackError, feedbackSuccess } from './feedback';

const handleResponse = async (response, showErrorOnly) => {
  const responseApi = await response;

  if (responseApi.statusCode === 200) {
    if (!showErrorOnly) {
      feedbackSuccess();
    }
  }
  if (responseApi.statusCode !== 200) {
    if (responseApi.statusCode === 409 || responseApi.statusCode === 400) {
      let msgError = responseApi.message;
      let descError = responseApi.description;
      feedbackError(msgError, descError);
    } else {
      feedbackError();
    }
  }
};

export function fetchApi(config) {
  const {
    method,
    url,
    data,
    token,
    contentType,
    showNotification,
    showNotificationError,
  } = config;

  const urlApi = `${basePath}/${url}`;

  let params = {
    method,
    headers: {
      'Content-Type': contentType || 'application/json',
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (data) {
    params = { ...params, body: JSON.stringify(data) };
  }

  if (token) {
    params = {
      ...params,
      headers: { ...params.headers, Authorization: `Bearer ${token}` },
    };
  }

  // const params = {
  //     method: method,
  //     headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         Accept: '*/*',
  //     },
  // };

  return fetch(urlApi, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (showNotification || showNotificationError)
        handleResponse(result, showNotificationError);

      return result;
    })
    .catch((err) => {});
}

export function fetchBlob(config) {
  const {
    method,
    url,
    data,
    token,
    contentType,
    showNotification,
    showNotificationError,
  } = config;

  const urlApi = `${basePath}/${url}`;

  let params = {
    method,
    headers: {
      'Content-Type': contentType || 'application/json',
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (data) {
    params = { ...params, body: JSON.stringify(data) };
  }

  if (token) {
    params = {
      ...params,
      headers: { ...params.headers, Authorization: `Bearer ${token}` },
    };
  }

  // const params = {
  //     method: method,
  //     headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         Accept: '*/*',
  //     },
  // };

  return fetch(urlApi, params)
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      if (showNotification || showNotificationError)
        handleResponse(result, showNotificationError);

      return result;
    })
    .catch((err) => {});
}
